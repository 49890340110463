import type { FC } from "react";
import { useEffect, useState } from "react";

import { Button, notification, Popconfirm, Space, Table, Tag } from "antd";
import { format } from "date-fns";

import type { Quote } from "@omi-lab/cresus-typescript";
import { QuoteStatus } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

interface Props {
  organizationId: string;
}

export const OrganizationQuotesDetails: FC<Props> = (props) => {
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(true);
  const [isDeletingQuote, setIsDeletingQuote] = useState(false);

  const [quotes, setQuotes] = useState<Quote[]>([]);

  const [quotesClient] = useClientsStore((store) => [store.quotesClient]);

  useEffect(() => {
    if (!props.organizationId) {
      setIsLoadingQuotes(false);
      return;
    }

    const listQuotes = async () => {
      try {
        await quotesClient
          .listQuotes({
            organizationId: props.organizationId,
            returnRelatedCoupon: true,
            returnRelatedProductInstance: true,
          })
          .then((response) => setQuotes(response.data));
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoadingQuotes(false);
      }
    };

    listQuotes();
  }, [props.organizationId, quotesClient]);

  const deleteQuote = async (id: string) => {
    try {
      setIsDeletingQuote(true);
      await quotesClient.deleteQuote({
        quoteId: id,
      });

      setQuotes((quotes) => quotes.filter((quote) => quote.id !== id));

      notification.success({
        message: "Successfully deleted the quote.",
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsDeletingQuote(false);
    }
  };

  return (
    <>
      <Table
        dataSource={quotes}
        loading={isLoadingQuotes}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "10%",
          },
          {
            title: "Status",
            key: "status",
            dataIndex: "status",
            width: "10%",
          },
          {
            title: "Expires at",
            key: "expiresAt",
            dataIndex: "expiresAt",
            render: (_, record: Quote) => (
              <p>
                {record.expiresAt
                  ? format(new Date(record.expiresAt), "yyyy-MM-dd HH:mm:ss")
                  : "Never"}
              </p>
            ),
            width: "10%",
          },
          {
            title: "Coupon",
            key: "coupon",
            render: (_, record: Quote) => <p>{record.coupon?.percentOff} %</p>,
            width: "10%",
          },
          {
            title: "Product",
            key: "product",
            render: (_, record: Quote) => (
              <p>
                {record.productInstance?.product?.name} (
                {record.productInstance?.unitCount} SKUs)
              </p>
            ),
            width: "10%",
          },
          {
            title: "Addons",
            key: "addons",
            render: (_, record: Quote) => (
              <p>
                {record.productInstance?.addons?.map((addon) => (
                  <Tag key={addon.product?.name}>{addon.product?.name}</Tag>
                ))}
              </p>
            ),
            width: "10%",
          },
          {
            title: "Created at",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (_, record: Quote) => (
              <p>
                {format(new Date(record.createdAt!), "yyyy-MM-dd HH:mm:ss")}
              </p>
            ),
            width: "10%",
          },
          {
            title: "Action",
            key: "action",
            render: (_, record: Quote) => (
              <Space>
                {record.status === QuoteStatus.PendingApproval && (
                  <Popconfirm
                    title="Are you sure you want to delete this quote ?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteQuote(record.id)}
                    okButtonProps={{ loading: isDeletingQuote }}
                  >
                    <Button type="primary" disabled={isDeletingQuote}>
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            ),
            width: "15%",
          },
        ]}
      />
    </>
  );
};
