import styled from "styled-components";

export const Container = styled.div`
  margin: 5px 0 5px 0;
  border: 2px dashed #eeeeee;
  padding: 10px;
  background: #fafafa;
  color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.24s ease-in-out;
  cursor: pointer;

  :hover {
    border: 2px dashed #1890ff;
    color: #1890ff;
  }
`;
