import type { FC } from "react";

import { Select } from "antd";

import { useSearchOrganizations } from "../hooks/useSearchOrganizations";

interface Props {
  placeholder?: string;
  showId?: boolean;
  value?: string[];
  onSelect: (id: string) => void;
  onDeselect: (id: string) => void;
  onClear: () => void;
}

export const OrganizationSearch: FC<Props> = (props) => {
  const { organizations, setSearch } = useSearchOrganizations();

  return (
    <Select
      mode="multiple"
      allowClear
      showSearch
      style={{ width: "100%" }}
      placeholder={props.placeholder ?? "Search for organizations"}
      filterOption={false}
      defaultValue={props.value || []}
      onSearch={setSearch}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      onClear={props.onClear}
    >
      {organizations.map((organization) => (
        <Select.Option value={organization.id} key={organization.id}>
          {organization.name} {props.showId ? `(${organization.id})` : null}
        </Select.Option>
      ))}
    </Select>
  );
};
