import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  DeleteOutlined,
  EditFilled,
  TranslationOutlined,
} from "@ant-design/icons";
import {
  Card,
  Image,
  notification,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";

import type {
  SceneTemplate,
  SceneTemplateCollection,
  SceneTemplateTag,
  SceneTemplateVisibility,
  UpdateSceneTemplateBody,
} from "@omi-lab/atlas-typescript";
import { SceneTemplateCapabilities } from "@omi-lab/atlas-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";

import { TemplateEditResourcesModal } from "./TemplateEditResourcesModal";
import { TemplateEditTranslationsModal } from "./TemplateEditTranslationsModal";

const SelectableSceneTemplateCapabilities = {
  [SceneTemplateCapabilities.CustomPreview]: "Custom preview",
  [SceneTemplateCapabilities.Packshot]: "Packshot",
  [SceneTemplateCapabilities.Studio]: "Studio",
  [SceneTemplateCapabilities.AiBackground]: "AI Background",
  [SceneTemplateCapabilities.ObjectPreview]: "Object Preview",
  [SceneTemplateCapabilities.ObjectThumbnail]: "Object Thumbnail",
};

type Props = {
  mode?: "select" | "view";
  onSelect?: (id: string) => void;
  tag?: string;
  selectedTemplatesId?: string[];
  sceneTemplate: SceneTemplate;
  updateSceneTemplate: (
    sceneTemplateId: string,
    body: UpdateSceneTemplateBody,
  ) => Promise<void>;
  deleteSceneTemplate: () => Promise<void>;
  collections: SceneTemplateCollection[];
  tags: SceneTemplateTag[];
};

export const SceneCard: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<ModelFile>();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isEditResourcesModalVisible, setIsEditResourcesModalVisible] =
    useState(false);
  const [isEditTranslationsModalVisible, setIsEditTranslationsModalVisible] =
    useState(false);

  const filesClient = useClientsStore((state) => state.filesClient);

  useEffect(() => {
    if (!props.sceneTemplate.thumbnailFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.sceneTemplate.thumbnailFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, props.sceneTemplate]);

  const deleteSceneTemplate = async () => {
    try {
      setIsDeleting(true);
      await props.deleteSceneTemplate();
    } finally {
      setIsDeleting(false);
    }
  };

  const updateSceneTemplate = async (body: UpdateSceneTemplateBody) => {
    try {
      setIsUpdating(true);
      await props.updateSceneTemplate(props.sceneTemplate.id, body);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <Card
        key={props.selectedTemplatesId
          ?.includes(props.sceneTemplate.id)
          .toString()}
        style={{
          border: props.selectedTemplatesId?.includes(props.sceneTemplate.id)
            ? "2px solid green"
            : "2px solid lightgrey",
          cursor: props.mode === "select" ? "pointer" : "default",
        }}
        onClick={() =>
          props.mode === "select" &&
          props.onSelect &&
          props.onSelect(props.sceneTemplate.id)
        }
        hoverable={false}
        actions={
          props.mode === "view"
            ? [
                <Popconfirm
                  title="Are you sure to delete this template?"
                  onConfirm={deleteSceneTemplate}
                  okText="Yes"
                  okButtonProps={{ loading: isDeleting }}
                  cancelText="No"
                  key="delete-template"
                >
                  <DeleteOutlined
                    key="delete"
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </Popconfirm>,
                props.sceneTemplate.nameTextContent ? (
                  <TranslationOutlined
                    key="translate"
                    onClick={() => setIsEditTranslationsModalVisible(true)}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                ) : undefined,
                <EditFilled
                  key="edit"
                  onClick={() => setIsEditResourcesModalVisible(true)}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />,
              ]
            : undefined
        }
      >
        <Skeleton loading={isLoading} avatar active>
          <Space
            style={{
              width: "100%",
              height: props.mode === "view" ? 520 : "auto",
              overflowY: "scroll",
            }}
            direction="vertical"
            size="large"
          >
            <Image src={thumbnail?.url ?? ""} />
            <Card.Meta
              title={
                <Tooltip title={props.sceneTemplate.name}>
                  <span>{props.sceneTemplate.name}</span>
                </Tooltip>
              }
              description={props.sceneTemplate.description}
            />

            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(props.sceneTemplate.id);
                notification.success({ message: "Copied to clipboard" });
              }}
            >
              {props.sceneTemplate.id}
            </div>
            {props.mode === "view" ? (
              <>
                <Select
                  mode="tags"
                  placeholder="Select tags"
                  loading={isUpdating}
                  style={{ width: "100%" }}
                  showArrow
                  maxTagCount={1}
                  value={(props.sceneTemplate.tags || []).map(
                    (tag) => tag.name,
                  )}
                  onSelect={(key) =>
                    updateSceneTemplate({
                      name: props.sceneTemplate.name,
                      thumbnailFileId: props.sceneTemplate.thumbnailFileId,
                      visibility: props.sceneTemplate
                        .visibility as SceneTemplateVisibility,
                      organizationId: props.sceneTemplate.organizationId,
                      tags: [
                        ...(props.sceneTemplate.tags || [])
                          .filter(({ name }) => name !== key)
                          .map((tag) => tag.name),
                        key,
                      ],
                    })
                  }
                  onDeselect={(key) => {
                    const updatedTags = (props.sceneTemplate.tags || [])
                      .filter(({ name }) => name !== key)
                      .map((tag) => tag.name);

                    updateSceneTemplate({
                      name: props.sceneTemplate.name,
                      thumbnailFileId: props.sceneTemplate.thumbnailFileId,
                      visibility: props.sceneTemplate
                        .visibility as SceneTemplateVisibility,
                      organizationId: props.sceneTemplate.organizationId,
                      tags: updatedTags.length ? updatedTags : [],
                    });
                  }}
                >
                  {props.tags.map((tag) => (
                    <Select.Option key={tag.name} value={tag.name}>
                      {tag.name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  mode="multiple"
                  placeholder="Select collections"
                  loading={isUpdating}
                  style={{ width: "100%" }}
                  showArrow
                  maxTagCount={1}
                  value={(props.sceneTemplate.collections || []).map(
                    (collection) => collection.path,
                  )}
                  onSelect={(key) =>
                    updateSceneTemplate({
                      name: props.sceneTemplate.name,
                      thumbnailFileId: props.sceneTemplate.thumbnailFileId,
                      visibility: props.sceneTemplate
                        .visibility as SceneTemplateVisibility,
                      organizationId: props.sceneTemplate.organizationId,
                      collections: [
                        ...(props.sceneTemplate.collections || []).filter(
                          ({ path }) => path !== key,
                        ),
                        props.collections.find(({ path }) => path === key)!,
                      ],
                    })
                  }
                  onDeselect={(key) =>
                    updateSceneTemplate({
                      name: props.sceneTemplate.name,
                      thumbnailFileId: props.sceneTemplate.thumbnailFileId,
                      visibility: props.sceneTemplate
                        .visibility as SceneTemplateVisibility,
                      organizationId: props.sceneTemplate.organizationId,
                      collections: (
                        props.sceneTemplate.collections || []
                      ).filter(({ path }) => path !== key),
                    })
                  }
                >
                  {props.collections.map((collection) => (
                    <>
                      <Select.Option
                        key={collection.path}
                        value={collection.path}
                      >
                        {collection.name} - {collection.path}
                      </Select.Option>
                      {collection.collections?.map((collection) => (
                        <>
                          <Select.Option
                            key={collection.path}
                            value={collection.path}
                          >
                            {collection.name} - {collection.path}
                          </Select.Option>
                        </>
                      ))}
                    </>
                  ))}
                </Select>
                <Select
                  mode="multiple"
                  placeholder="Select capabilities"
                  loading={isUpdating}
                  style={{ width: "100%" }}
                  showArrow
                  maxTagCount={1}
                  value={(props.sceneTemplate.capabilities || []).map(
                    (capability) => capability.name,
                  )}
                  onSelect={(key) =>
                    updateSceneTemplate({
                      name: props.sceneTemplate.name,
                      thumbnailFileId: props.sceneTemplate.thumbnailFileId,
                      visibility: props.sceneTemplate
                        .visibility as SceneTemplateVisibility,
                      organizationId: props.sceneTemplate.organizationId,
                      capabilities: [
                        ...(props.sceneTemplate.capabilities || []).filter(
                          ({ name }) => name !== key,
                        ),
                        { name: key },
                      ],
                    })
                  }
                  onDeselect={(key) =>
                    updateSceneTemplate({
                      name: props.sceneTemplate.name,
                      thumbnailFileId: props.sceneTemplate.thumbnailFileId,
                      visibility: props.sceneTemplate
                        .visibility as SceneTemplateVisibility,
                      organizationId: props.sceneTemplate.organizationId,
                      capabilities: (
                        props.sceneTemplate.capabilities || []
                      ).filter(({ name }) => name !== key),
                    })
                  }
                >
                  {Object.entries(SelectableSceneTemplateCapabilities).map(
                    ([capability, label]) => (
                      <Select.Option key={capability} value={capability}>
                        {label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </>
            ) : null}
          </Space>
        </Skeleton>
      </Card>
      <TemplateEditResourcesModal
        sceneTemplateId={props.sceneTemplate.id}
        visible={isEditResourcesModalVisible}
        hide={() => setIsEditResourcesModalVisible(false)}
      />
      {props.sceneTemplate.nameTextContent && (
        <TemplateEditTranslationsModal
          textContent={props.sceneTemplate.nameTextContent}
          visible={isEditTranslationsModalVisible}
          hide={() => setIsEditTranslationsModalVisible(false)}
        />
      )}
    </>
  );
};
