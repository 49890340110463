import { useEffect, useState } from "react";

import type { ObjectTag } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useSearchTags() {
  const [search, setSearch] = useState<undefined | string>();
  const [tags, setTags] = useState<ObjectTag[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [objectTagsClient] = useClientsStore((store) => [
    store.objectTagsClient,
  ]);

  useEffect(() => {
    if (search === undefined) return;
    (async () => {
      setIsLoading(true);
      try {
        await objectTagsClient
          .listObjectTags({
            includes: search,
            page: 1,
            pageSize: 50,
          })
          .then((response) => setTags(response.data));
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [objectTagsClient, search]);

  return { tags, search, setSearch, isLoading };
}
