import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Divider,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { format } from "date-fns";

import type { CreateProductBody, Product } from "@omi-lab/atlas-typescript";
import { ObjectVisibility } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { ObjectSearch } from "../../Billing/components/ObjectSearch";

import { OrganizationProductAdd } from "./OrganizationProductAdd";

interface Props {
  organizationId: string;
  setProductCount: React.Dispatch<React.SetStateAction<number>>;
}

export const OrganizationProductsDetails: FC<Props> = (props) => {
  const [isLoadingProducts, setIsLoadingQuotes] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [isProductEditModalVisible, setIsProductEditModalVisible] =
    useState(false);
  const [isProductAddModalVisible, setIsProductAddModalVisible] =
    useState(false);

  const [productsClient] = useClientsStore((store) => [
    store.atlasProductsClient,
  ]);

  useEffect(() => {
    if (products.length >= pageSize) {
      setPageCount(page + 1);
    }
  }, [products, pageSize, setPageCount, page]);

  const listProducts = useCallback(async () => {
    try {
      await productsClient
        .listProducts({
          organizationId: props.organizationId,
          returnRelatedObjects: true,
          page,
          pageSize,
        })
        .then((response) => setProducts(response.data));
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoadingQuotes(false);
    }
  }, [page, pageSize, productsClient, props.organizationId]);
  useEffect(() => {
    listProducts();
  }, [props.organizationId, page, pageSize, listProducts, productsClient]);

  const createProduct = async (body: CreateProductBody) => {
    try {
      setIsLoadingQuotes(true);
      await productsClient.createProduct({ body });
      await listProducts();
      setIsProductEditModalVisible(false);
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoadingQuotes(false);
    }
  };

  const updateProduct = async (id: string, objectIds: string[]) => {
    try {
      setIsLoadingQuotes(true);
      await productsClient.updateProduct({
        productId: id,
        body: {
          objects: objectIds,
        },
      });
      await listProducts();
      setIsProductEditModalVisible(false);
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoadingQuotes(false);
    }
  };

  const deleteProduct = async (id: string) => {
    try {
      setIsLoadingQuotes(true);
      await productsClient.deleteProduct({ productId: id });
      await listProducts();
      props.setProductCount((count) => count - 1);
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoadingQuotes(false);
    }
  };

  return (
    <>
      <Row justify="end">
        <Button
          type="primary"
          onClick={() => setIsProductAddModalVisible(true)}
        >
          +
        </Button>
      </Row>
      <Table
        pagination={false}
        dataSource={products}
        loading={isLoadingProducts}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "10%",
          },
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            width: "10%",
          },
          {
            title: "Created at",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (_, record: Product) => (
              <p>
                {format(new Date(record.createdAt!), "yyyy-MM-dd HH:mm:ss")}
              </p>
            ),
            width: "10%",
          },
          {
            title: "Objects",
            key: "objects",
            dataIndex: "objects",
            render: (_, record: Product) => (
              <div key={record.objects?.toString()} style={{ width: "100%" }}>
                <Select
                  mode="multiple"
                  onDeselect={(e) =>
                    updateProduct(
                      record.id,
                      record.objects
                        ?.filter((obj) => obj.id !== e)
                        .map((obj) => obj.id) || [],
                    )
                  }
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={false}
                  value={record.objects?.map((obj) => obj.id) || []}
                >
                  {record.objects?.map((obj) => (
                    <Select.Option value={obj.id} key={obj.id}>
                      {obj.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ),
            width: "30%",
          },
          {
            title: "Action",
            key: "action",
            render: (_, record: Product) => (
              <Space>
                <Popconfirm
                  title="Are you sure you want to delete this product ?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deleteProduct(record.id)}
                  okButtonProps={{ loading: isLoadingProducts }}
                >
                  <Button type="primary" danger disabled={isLoadingProducts}>
                    Delete
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedProduct(record);
                    setSelectedObjects(
                      record.objects?.map((obj) => obj.id) || [],
                    );
                    setIsProductEditModalVisible(true);
                  }}
                  disabled={isLoadingProducts}
                >
                  Add Object
                </Button>
              </Space>
            ),
            width: "15%",
          },
        ]}
      />
      <Divider />
      <Pagination
        current={page}
        onChange={setPage}
        total={(pageCount - 1) * pageSize + products.length}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger
      />
      <Modal
        width="50%"
        open={isProductEditModalVisible}
        okText={"Add Object to Product"}
        onCancel={() => setIsProductEditModalVisible(false)}
        onOk={async () =>
          selectedProduct &&
          (await updateProduct(selectedProduct.id, selectedObjects))
        }
      >
        <ObjectSearch
          key={selectedObjects.toString()}
          value={selectedObjects}
          visibilityIn={[ObjectVisibility.Private]}
          orgId={props.organizationId}
          onClear={() => setSelectedObjects([])}
          onDeselect={(id) =>
            setSelectedObjects(selectedObjects.filter((obj) => obj !== id))
          }
          onSelect={(id) =>
            setSelectedObjects([...(selectedObjects || []), id])
          }
        />
      </Modal>
      <OrganizationProductAdd
        isVisible={isProductAddModalVisible}
        setIsVisible={(isVisible) => setIsProductAddModalVisible(isVisible)}
        createProduct={createProduct}
        organizationId={props.organizationId}
      />
    </>
  );
};
