import type { FC } from "react";
import { useEffect, useState } from "react";

import { Button, notification, Popconfirm, Table } from "antd";

import type { Membership } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

interface Props {
  organizationId: string;
}

export const OrganizationMembers: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [memberships, setMemberships] = useState<Membership[]>([]);

  const organizationsClient = useClientsStore(
    (store) => store.organizationsClient,
  );

  useEffect(() => {
    if (!props.organizationId) {
      return;
    }

    const listMemberships = async () => {
      try {
        setIsLoading(true);
        await organizationsClient
          .listOrganizationMemberships({
            organizationId: props.organizationId,
            returnRelatedAccount: true,
            returnRelatedTeams: true,
          })
          .then((response) => setMemberships(response.data));
        setIsLoading(false);
      } catch (error: unknown) {
        showErrorNotification(error);
      }
    };

    listMemberships();
  }, [props.organizationId, organizationsClient]);

  const removeMember = async (id: string) => {
    try {
      setIsLoading(true);
      await organizationsClient.removeMember({
        organizationId: props.organizationId,
        membershipId: id,
      });
      setMemberships((memberships) =>
        memberships.filter((membership) => membership.id !== id),
      );
      notification.success({
        message: "Successfully removed the member from the organization.",
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Table
      dataSource={memberships}
      loading={isLoading}
      columns={[
        {
          title: "ID",
          key: "id",
          dataIndex: "id",
          width: "20%",
        },
        {
          title: "Email",
          key: "account.email",
          dataIndex: ["account", "email"],
          width: "20%",
        },
        {
          title: "First Name",
          key: "account.firstName",
          dataIndex: ["account", "firstName"],
          width: "20%",
        },
        {
          title: "Last Name",
          key: "account.lastName",
          dataIndex: ["account", "lastName"],
          width: "20%",
        },
        {
          title: "Action",
          key: "action",
          render: (_, record: Membership) => (
            <Popconfirm
              title="Are you sure to remove this member ?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => removeMember(record.id)}
              okButtonProps={{ loading: isLoading }}
            >
              <Button type="primary" danger>
                Remove
              </Button>
            </Popconfirm>
          ),
          width: "20%",
        },
      ]}
    />
  );
};
