import type { FC } from "react";
import { useEffect, useState } from "react";

import { Image } from "antd";

import type { SceneTemplate } from "@omi-lab/atlas-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";

interface Props {
  sceneTemplate: SceneTemplate;
}

export const SceneTemplateThumbnail: FC<Props> = (props) => {
  const [thumbnail, setThumbnail] = useState<ModelFile>();

  const filesClient = useClientsStore((state) => state.filesClient);

  useEffect(() => {
    if (!props.sceneTemplate.thumbnailFileId) {
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.sceneTemplate.thumbnailFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail().then((response) => setThumbnail(response.data));
  }, [setThumbnail, filesClient, props.sceneTemplate]);

  return (
    <Image
      style={{ padding: "5px" }}
      width="150px"
      src={thumbnail?.url ?? ""}
    />
  );
};
