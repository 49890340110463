import { useEffect, useState } from "react";

import type { Subscription } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useListSubscriptions(params: {
  organizationId: string;
  isActive?: boolean;
}) {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [subscriptionsClient] = useClientsStore((store) => [
    store.subscriptionsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await subscriptionsClient.listSubscriptions({
          organizationId: params.organizationId,
          returnRelatedScheduledUpdate: true,
          returnRelatedNextPhase: true,
          returnRelatedProductInstance: true,
          returnRelatedCoupon: true,
          isActive: params.isActive,
        });

        setSubscriptions(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [subscriptionsClient, params.organizationId, params.isActive]);

  return { subscriptions, setSubscriptions, isLoading };
}
