import { useEffect, useState } from "react";

import { Flex, Image, List, Pagination } from "antd";

import type {
  ListRatioPresetGroupsV2ResponseBody,
  RatioPreset,
} from "@omi-lab/atlas-typescript";

import { Dropzone } from "src/components/Dropzone";
import { useFile } from "src/hooks/useFile";
import { useClientsStore } from "src/store/clients";

type RatioPresetImgProps = {
  thumbnailId?: string;
  ratioPresetSlug: string;
};

const RatioPresetImg = ({
  thumbnailId,
  ratioPresetSlug,
}: RatioPresetImgProps) => {
  const [uploadFile, setUploadFile] = useState<File>();
  const { filesClient, ratioPresetsClient } = useClientsStore((state) => state);
  const { file } = useFile(thumbnailId, {
    returnRelatedCategories: true,
  });

  const url = file?.url ?? undefined;

  const updateRatioPreset = async (thumbnailFileId: string) => {
    await ratioPresetsClient.updateRatioPresetV2({
      body: {
        thumbnailFileId,
      },
      ratioPresetSlug,
    });
  };

  const uploadThumbnailFile = async (file: File) => {
    const { data } = await filesClient.uploadFile({
      file,
      name: file.name,
    });

    await updateRatioPreset(data.id);
  };

  const handleOnDrop = async (files: File[]) => {
    if (!files[0]) return;
    setUploadFile(files[0]);
    uploadThumbnailFile(files[0]);
  };

  if (!url) {
    return (
      <Dropzone
        onDrop={handleOnDrop}
        files={uploadFile ? [uploadFile] : []}
        accept="image/*"
        maxFiles={1}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <Image height={20} alt="thumbnail" src={url} />;
      <Dropzone
        onDrop={handleOnDrop}
        files={uploadFile ? [uploadFile] : []}
        accept="image/*"
        maxFiles={1}
      />
    </div>
  );
};

type RatioPresetCardProps = {
  ratioPreset: RatioPreset;
};

const RatioPresetCard = ({ ratioPreset }: RatioPresetCardProps) => {
  return (
    <>
      <List.Item
        key={ratioPreset.name}
        title={ratioPreset.name ?? ""}
        extra={
          <RatioPresetImg
            ratioPresetSlug={ratioPreset.slug}
            thumbnailId={ratioPreset.thumbnailFileId ?? undefined}
          />
        }
      >
        <List.Item.Meta
          title={ratioPreset.name}
          description={
            <div className="flex-col flex">
              <div>{`preset name: ${ratioPreset.name ?? "N/A"}`}</div>
              <div>{`size: [${ratioPreset.width}x${ratioPreset.createdAt}]`}</div>
              <div>{`slug: ${ratioPreset.slug}`}</div>
            </div>
          }
        />
      </List.Item>
    </>
  );
};

const PAGE_SIZE = 10;

export const RatioPresets = () => {
  const { ratioPresetsClient } = useClientsStore((state) => state);

  const [page, setPage] = useState(1);
  const [ratioPresetGroups, setRatioPresetGroups] =
    useState<ListRatioPresetGroupsV2ResponseBody>();

  useEffect(() => {
    const listRatioPresets = async () =>
      ratioPresetsClient
        .listRatioPresetGroupsV2({
          page,
          pageSize: PAGE_SIZE,
        })
        .then((response) => {
          setRatioPresetGroups(response.data);
        });

    listRatioPresets();
  }, [ratioPresetsClient, page]);

  return (
    <>
      <Flex gap="middle" vertical style={{ padding: "1rem" }}>
        {ratioPresetGroups?.data.map((group) => (
          <>
            <h2>{group.name}</h2>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={group.presets ?? []}
              key={JSON.stringify(group.presets)}
              renderItem={(item) => <RatioPresetCard ratioPreset={item} />}
            />
          </>
        ))}
        <Pagination
          current={page}
          pageSize={PAGE_SIZE}
          total={ratioPresetGroups?.count ?? 0}
          onChange={setPage}
        />
      </Flex>
    </>
  );
};

export default RatioPresets;
