import type { FC } from "react";
import { useState } from "react";

import { Form, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import type { CreateProjectTemplateBody } from "@omi-lab/atlas-typescript";

import { useListProductVerticals, useListSceneTemplates } from "../hooks";

interface Props {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  createProjectTemplate: (body: CreateProjectTemplateBody) => Promise<void>;
}

export const AddProjectTemplateModal: FC<Props> = ({
  isVisible,
  setIsVisible,
  createProjectTemplate,
}) => {
  const [nameIncludes, setNameIncludes] = useState<string>();

  const { verticals } = useListProductVerticals();
  const { sceneTemplates } = useListSceneTemplates({
    page: 1,
    pageSize: 15,
    nameIncludes,
  });

  const { values, setValues, handleChange, handleSubmit, isValid } = useFormik({
    initialValues: {
      name: "",
      productVerticalName: "",
      sceneTemplateIds: [],
    } as CreateProjectTemplateBody,
    validationSchema: Yup.object({
      name: Yup.string().required(),
      productVerticalName: Yup.string().required(),
      sceneTemplateIds: Yup.array().of(Yup.string().required()).required(),
    }).required(),
    onSubmit: async (values, { resetForm }) => {
      await createProjectTemplate(values);
      resetForm();
    },
  });

  return (
    <Modal
      title="Add a vertical"
      open={isVisible}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: !isValid }}
      onCancel={() => setIsVisible(false)}
    >
      <Form.Item>
        <Input
          placeholder="Name"
          name="name"
          onChange={handleChange}
          value={values.name}
        />
      </Form.Item>
      <Form.Item>
        <Select
          value={values.productVerticalName}
          onSelect={(value) =>
            setValues((values) => ({
              ...values,
              productVerticalName: value,
            }))
          }
        >
          {verticals.map((item, index) => (
            <Select.Option key={index} value={item.name}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Select
          mode="multiple"
          value={values.sceneTemplateIds}
          filterOption={false}
          allowClear
          showSearch
          onSearch={(value) => setNameIncludes(value)}
          onSelect={(value) =>
            setValues((values) => ({
              ...values,
              sceneTemplateIds: [...values.sceneTemplateIds, value],
            }))
          }
          onDeselect={(value) =>
            setValues((values) => ({
              ...values,
              sceneTemplateIds: values.sceneTemplateIds.filter(
                (str) => str !== value,
              ),
            }))
          }
          onClear={() =>
            setValues((values) => ({
              ...values,
              sceneTemplateIds: [],
            }))
          }
        >
          {sceneTemplates.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Modal>
  );
};
