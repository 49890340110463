import { useEffect, useState } from "react";

import type { Coupon } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useListCoupons(params: {
  nameIncludes?: string;
  page?: number;
  pageSize?: number;
}) {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const client = useClientsStore((store) => store.couponsClient);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await client.listCoupons({
          nameIncludes: params.nameIncludes,
          page: params.page,
          pageSize: params.pageSize,
        });

        setCoupons(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [client, params.nameIncludes, params.page, params.pageSize]);

  return { coupons, setCoupons, isLoading, pageCount };
}
