import { Currency } from "@omi-lab/cresus-typescript";

export const currencyToSymbol = (currency: Currency) => {
  switch (currency) {
    case Currency.Usd:
      return "$";
    case Currency.Eur:
      return "€";
    default:
      return "";
  }
};
