import { Descriptions, Modal } from "antd";

import type { Scene } from "@omi-lab/atlas-typescript";

import { useGetCamera } from "../hooks/useGetCamera";
import { useGetLightSet } from "../hooks/useGetLightSet";
import { useGetSkybox } from "../hooks/useGetSkybox";

type RenderInformationModaleProps = {
  isOpen: boolean;
  scene: Scene;
  setIsOpen: (isOpen: boolean) => void;
};

const RenderInformationModal = ({
  setIsOpen,
  isOpen,
  scene,
}: RenderInformationModaleProps) => {
  const { lightSet } = useGetLightSet(scene.lightSetId);
  const { camera } = useGetCamera(scene.cameraId);
  const { skyBox } = useGetSkybox(scene.skyBoxId);

  return (
    <Modal
      footer={(_, { CancelBtn }) => <CancelBtn />}
      onCancel={() => setIsOpen(false)}
      open={isOpen}
    >
      <Descriptions column={1} layout="vertical" title="Render information">
        <Descriptions.Item label="Scene Name">{scene.name}</Descriptions.Item>
        <Descriptions.Item label="Light">
          {lightSet?.id} - {lightSet?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Camera">
          {camera?.id} - {camera?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Skybox">
          {skyBox?.id} - {skyBox?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Ratio">
          {scene.width}px x {scene.height}px
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default RenderInformationModal;
