import type { FC } from "react";
import { useEffect, useState } from "react";

import { List, Modal, Pagination } from "antd";

import type {
  Material,
  MaterialCollection,
  MaterialOrderBy,
  MaterialTag,
  UpdateMaterialBody,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";

import { MaterialCard } from "./MaterialCard";

type Props = {
  filter: string;
  orderBy?: MaterialOrderBy;
  collectionFilters?: string[];
  collections: MaterialCollection[];
  tags: MaterialTag[];
};

export const MaterialList: FC<Props> = (props) => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const materialsClient = useClientsStore((state) => state.materialsClient);

  useEffect(() => {
    const getMaterials = async () =>
      materialsClient
        .listMaterials({
          page,
          pageSize,
          returnRelatedTags: true,
          returnRelatedCollections: true,
          visibilityIn: ["public", "private", "hidden"],
          nameIncludes: props.filter,
          orderBy: props.orderBy,
          collectionPaths: props.collectionFilters,
        })
        .then((response) => {
          if (response.data.length >= pageSize) {
            setPageCount(page + 1);
          }
          setMaterials(response.data);
        });

    getMaterials();
  }, [
    materialsClient,
    setMaterials,
    page,
    pageSize,
    props.filter,
    props.orderBy,
    props.collectionFilters,
  ]);

  const updateMaterial = async (id: string, body: UpdateMaterialBody) => {
    try {
      const { data } = await materialsClient.updateMaterial({
        materialId: id,
        body,
        returnRelatedCollections: true,
        returnRelatedTags: true,
      });

      setMaterials(
        materials.map((material) => (material.id === id ? data : material)),
      );
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while updating the material",
        content: `${error}`,
      });
    }
  };

  const deleteMaterial = async (id: string) => {
    try {
      await materialsClient.deleteMaterial({
        materialId: id,
      });

      setMaterials(materials.filter((material) => material.id !== id));
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while deleting the material",
        content: `${error}`,
      });
    }
  };

  return (
    <>
      <List
        grid={{
          gutter: 4,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 9,
        }}
        dataSource={materials}
        renderItem={(material) => (
          <List.Item>
            <MaterialCard
              {...props}
              key={material.id}
              material={material}
              deleteMaterial={() => deleteMaterial(material.id!)}
              updateMaterial={updateMaterial}
              tags={props.tags}
              collections={props.collections}
            />
          </List.Item>
        )}
      />
      <Pagination
        current={page}
        onChange={setPage}
        total={pageCount * pageSize}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger
      />
    </>
  );
};
