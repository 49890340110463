import type { FC } from "react";
import { useState } from "react";

import { Input, Modal, notification } from "antd";
import Compressor from "compressorjs";
import { useFormik } from "formik";
import * as Yup from "yup";

import type { ObjectCollection } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { Dropzone } from "../../Dropzone";
import type {
  Collection,
  CreateCollectionBody,
  UpdateCollectionBody,
} from "../types";

interface Props {
  collection?: Collection | ObjectCollection;
  parentPath?: string;
  isOpen: boolean;
  createCollection: (element: CreateCollectionBody) => Promise<void>;
  updateCollection: (
    path: string,
    element: UpdateCollectionBody,
  ) => Promise<void>;
  onCancel: () => void;
}

const CreateCollectionValidationSchema = Yup.object({
  name: Yup.string().required(),
  path: Yup.string().required(),
  thumbnail: Yup.mixed().required(),
});

const UpdateCollectionValidationSchema = Yup.object({
  name: Yup.string().required(),
  thumbnail: Yup.mixed().required(),
});

export const AppendModal: FC<Props> = (props) => {
  const { values, setValues, isValid, handleSubmit, isSubmitting } = useFormik<
    CreateCollectionBody | UpdateCollectionBody
  >({
    onSubmit: (values) => onOk(values),
    validationSchema: props.collection
      ? UpdateCollectionValidationSchema
      : CreateCollectionValidationSchema,
    initialValues: {
      name: props.collection?.name,
      path: props.collection?.path,
      thumbnail: undefined,
      isHighlighted: props.collection?.isHighlighted,
      weight: props.collection?.weight,
    },
    validateOnMount: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const subpath = e.target.value.toLowerCase().replace(/ /gi, "_");

    setValues((values) => ({
      ...values,
      name: e.target.value,
      path: props.collection
        ? props.collection.path
        : props.parentPath
          ? `${props.parentPath}.${subpath}`
          : subpath,
    }));
  };

  const onOk = async (
    collection: CreateCollectionBody | UpdateCollectionBody,
  ) => {
    try {
      setIsLoading(true);
      if (props.collection) {
        await props.updateCollection(props.collection.path, collection);
      } else {
        await props.createCollection(collection as CreateCollectionBody);
      }
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={
        !props.collection ? "Create a new collection" : "Update the collection"
      }
      open={props.isOpen}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: !isValid || isSubmitting }}
      onCancel={props.onCancel}
      confirmLoading={isLoading}
    >
      <Input
        placeholder="Give your collection a name"
        name="name"
        onChange={onChange}
        value={values.name}
      />
      <Dropzone
        onDrop={async (files) => {
          const file = files[0];
          if (!file) return;
          new Compressor(file, {
            maxWidth: 562,
            convertSize: 30000,
            success: (compressed: File) => {
              setValues((collection) => ({
                ...collection,
                thumbnail: compressed,
              }));
            },
            error: (error) => {
              notification.error({
                message: error.message,
              });
            },
          });
        }}
        files={values.thumbnail ? [values.thumbnail] : []}
        accept="image/*"
        maxFiles={1}
      />
    </Modal>
  );
};
