import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  Button,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";

import type { Invitation } from "@omi-lab/ceos-typescript";
import { OrganizationPermission } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

interface Props {
  organizationId: string;
}

export const OrganizationInvitations: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [invitations, setInvitations] = useState<Invitation[]>([]);

  const [email, setEmail] = useState("");

  const organizationsClient = useClientsStore(
    (store) => store.organizationsClient,
  );

  useEffect(() => {
    if (!props.organizationId) {
      return;
    }

    const listInvitations = async () => {
      try {
        setIsLoading(true);
        await organizationsClient
          .listOrganizationInvitations({
            organizationId: props.organizationId,
          })
          .then((response) => setInvitations(response.data));
        setIsLoading(false);
      } catch (error: unknown) {
        showErrorNotification(error);
      }
    };

    listInvitations();
  }, [props.organizationId, organizationsClient]);

  const inviteMember = async (email: string) => {
    try {
      setIsLoading(true);
      const { data } = await organizationsClient.inviteMember({
        organizationId: props.organizationId,
        body: {
          permissions: Object.values(OrganizationPermission),
          email,
        },
      });

      setInvitations([...invitations, data]);

      notification.success({
        message: `Successfully invited ${email} to join the organization.`,
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setEmail("");
      setIsLoading(false);
    }
  };

  const cancelInvitation = async (id: string) => {
    try {
      setIsLoading(true);
      await organizationsClient.cancelInvitation({
        organizationId: props.organizationId,
        invitationId: id,
      });

      setInvitations((invitations) =>
        invitations.filter((invitation) => invitation.id !== id),
      );

      notification.success({
        message: "Successfully canceled the invitation.",
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row>
        <Input.Search
          type="email"
          name="email"
          placeholder="barbara@omi.so"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onSearch={() => inviteMember(email)}
          enterButton="Invite"
        />
      </Row>
      <Table
        dataSource={invitations}
        loading={isLoading}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "20%",
          },
          {
            title: "Email",
            key: "email",
            dataIndex: "email",
            width: "20%",
          },
          {
            title: "Access",
            key: "access",
            dataIndex: "access",
            width: "20%",
          },
          {
            title: "Status",
            key: "status",
            dataIndex: "status",
            width: "20%",
          },
          {
            title: "Action",
            key: "action",
            render: (_, record: Invitation) => (
              <Popconfirm
                title="Are you sure to cancel this invitation ?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => cancelInvitation(record.id)}
                okButtonProps={{ loading: isLoading }}
              >
                <Button type="primary" danger>
                  Cancel
                </Button>
              </Popconfirm>
            ),
            width: "20%",
          },
        ]}
      />
    </Space>
  );
};
