import type { FC } from "react";
import { useState } from "react";

import { Button, Col, Row, Table } from "antd";

import type { CreateProjectTemplateBody } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { AddProjectTemplateModal } from "../components/AddProjectTemplateModal";
import { SceneTemplateThumbnail } from "../components/SceneTemplateThumbnail";
import { useListProjectTemplates } from "../hooks";

export const ProjectTemplates: FC = () => {
  const client = useClientsStore((state) => state.projectTemplatesClient);

  const { projectTemplates, setProjectTemplates, isLoading } =
    useListProjectTemplates({ page: 1, pageSize: 100 });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const createProjectTemplate = async (body: CreateProjectTemplateBody) => {
    try {
      const { data } = await client.createProjectTemplate({
        body,
        returnRelatedSceneTemplates: true,
      });

      setProjectTemplates([...projectTemplates, data]);

      setIsModalVisible(false);
    } catch (error: unknown) {
      showErrorNotification(error);
    }
  };

  const deleteProjectTemplate = async (id: string) => {
    try {
      await client.deleteProjectTemplate({ projectTemplateId: id });

      setProjectTemplates(
        projectTemplates.filter((template) => template.id !== id),
      );
    } catch (error: unknown) {
      showErrorNotification(error);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Table
        loading={isLoading}
        dataSource={projectTemplates}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Vertical",
            dataIndex: "productVerticalName",
            key: "productVerticalName",
          },
          {
            title: "Templates",
            dataIndex: "sceneTemplates",
            key: "sceneTemplates",
            render: (_, record) => (
              <Row>
                {(record.sceneTemplates || []).map((template) => (
                  <SceneTemplateThumbnail
                    key={template.id}
                    sceneTemplate={template}
                  />
                ))}
              </Row>
            ),
          },
          {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
              <Button danger onClick={() => deleteProjectTemplate(record.id)}>
                Delete
              </Button>
            ),
          },
        ]}
      />
      <Button onClick={() => setIsModalVisible(true)}>Add</Button>
      <AddProjectTemplateModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        createProjectTemplate={createProjectTemplate}
      />
    </Col>
  );
};
