import type { FC } from "react";
import { useState } from "react";

import { Button, Col, Input, notification, Pagination, Row, Table } from "antd";

import type { CreateCouponBody } from "@omi-lab/cresus-typescript";
import { PlanCurrency } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { useListCoupons } from "../hooks";

import { CreateCouponModal } from "./CreateCouponModal";

export const Coupons: FC = () => {
  const [nameIncludes, setNameIncludes] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [isCreating, setIsCreating] = useState(false);

  const client = useClientsStore((store) => store.couponsClient);

  const { coupons, setCoupons, pageCount, isLoading } = useListCoupons({
    nameIncludes,
    page,
    pageSize,
  });

  const createCoupon = async (body: CreateCouponBody) => {
    try {
      setIsCreating(true);
      await client
        .createCoupon({
          body: {
            ...body,
            amountOff: body.amountOff ? body.amountOff * 100 : undefined,
            currency: body.amountOff ? PlanCurrency.Eur : undefined,
          },
        })
        .then(({ data }) => setCoupons([...coupons, data]));
      notification.success({
        message: "The coupon was successfully created.",
        duration: 1,
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Row gutter={16} justify="space-between" style={{ marginBottom: "10px" }}>
        <Col span={3}>
          <Input
            placeholder="Name"
            value={nameIncludes}
            onChange={(e) => setNameIncludes(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={() => setIsCreating(true)}>
            +
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={coupons.map((coupon) => ({
          ...coupon,
          key: coupon.id,
        }))}
        pagination={false}
        loading={isLoading}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "20%",
            align: "center",
          },
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            width: "20%",
            align: "center",
          },
          {
            title: "Currency",
            key: "currency",
            dataIndex: "currency",
            width: "20%",
            align: "center",
          },
          {
            title: "Percent off",
            key: "percentOff",
            dataIndex: "percentOff",
            width: "20%",
            align: "center",
          },
          {
            title: "Amount off",
            key: "amountOff",
            dataIndex: "amountOff",
            width: "20%",
            align: "center",
          },
        ]}
      />
      <Pagination
        style={{ marginTop: 20 }}
        current={page}
        total={pageCount * pageSize}
        onChange={setPage}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger
      />
      <CreateCouponModal
        createCoupon={createCoupon}
        close={() => setIsCreating(false)}
        isOpen={isCreating}
      />
    </Col>
  );
};
