import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import type {
  CountryCode,
  CreateSubscriptionBody,
  Subscription,
  TaxExemptEnum,
  UpdateSubscriptionBody,
} from "@omi-lab/cresus-typescript";
import {
  Currency,
  PaymentMethodTypeEnum,
  PlanType,
  SubscriptionUpdateSource,
} from "@omi-lab/cresus-typescript";

import { currencyToSymbol } from "src/utils/currency";
import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { useGetCustomer } from "../hooks/useGetCustomer";
import { useGetProduct } from "../hooks/useGetProduct";
import { useGetProductCount } from "../hooks/useGetProductCount";
import { useGetProductInstanceQuote } from "../hooks/useGetProductInstanceQuote";
import { useListCoupons } from "../hooks/useListCoupons";
import { useListPaymentMethods } from "../hooks/useListPaymentMethods";
import { useListProducts } from "../hooks/useListProducts";

import { OrganizationSubscriptionEditAddon } from "./OrganizationSubscriptionEditAddon";

interface Props {
  organizationId: string;
  subscription?: Subscription;
  close: (subscription?: Subscription) => void;
}

export const OrganizationSubscriptionEdit: FC<Props> = (props) => {
  const [nameIncludes, setNameIncludes] = useState<string>();
  const { product, setProduct } = useGetProduct(
    props.subscription?.productInstance?.productId,
  );
  const { productCount } = useGetProductCount({
    organizationId: props.organizationId,
  });

  const {
    customer,
    setCustomer,
    isLoading: isLoadingCustomer,
  } = useGetCustomer(props.organizationId);
  const { paymentMethods: cards, isLoading: isLoadingCards } =
    useListPaymentMethods(customer?.id, PaymentMethodTypeEnum.Card);
  const { paymentMethods: sepaDebits, isLoading: isLoadingSepaDebit } =
    useListPaymentMethods(customer?.id, PaymentMethodTypeEnum.SepaDebit);

  const { products, isLoading: isLoadingProducts } = useListProducts({
    nameIncludes: nameIncludes,
    page: 1,
    pageSize: 50,
  });
  const { coupons, isLoading: isLoadingCoupons } = useListCoupons({
    page: 1,
    pageSize: 50,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [subscriptionsClient, customersClient] = useClientsStore((store) => [
    store.subscriptionsClient,
    store.customersClient,
  ]);

  const { values, setValues, handleSubmit, isValid } = useFormik({
    initialValues: {
      paymentMethodId: props.subscription?.defaultPaymentMethodId,
      product: {
        productId: props.subscription?.productInstance?.productId,
        unitCount: props.subscription?.productInstance?.unitCount,
        addons: props.subscription?.productInstance?.addons?.map((addon) => ({
          productId: addon.productId,
          unitCount: addon.unitCount,
          targets: addon.targets?.map(({ targetId }) => targetId),
        })),
      },
      couponId: props.subscription?.coupon?.id,
      customerId: customer?.id,
      currency: props.subscription?.productInstance?.currency,
      allowNoPaymentMethod: false,
      force: false,
    } as CreateSubscriptionBody & {
      allowNoPaymentMethod: boolean;
      force?: boolean;
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required(),
      paymentMethodId: Yup.string()
        .when("allowNoPaymentMethod", {
          is: false,
          then: Yup.string().required(),
        })
        .nullable(),
      product: Yup.object()
        .shape({
          productId: Yup.string().optional().nullable(),
          unitCount: Yup.number().min(0).optional().nullable(),
        })
        .required(),
      allowNoPaymentMethod: Yup.bool().required(),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      if (!props.subscription) {
        return createSubscription(values);
      }
      if (props.subscription.isTrial) {
        return createSubscriptionAfterTrial(values);
      }
      return updateSubscription(values);
    },
  });

  useEffect(() => {
    setValues((values) => ({
      ...values,
      customerId: customer?.id || null,
      allowNoPaymentMethod: customer?.allowNoPaymentMethod || false,
    }));
  }, [customer, setValues]);

  const { instance } = useGetProductInstanceQuote({
    productId: values.product?.productId,
    unitCount: values.product?.unitCount || undefined,
    customerId: values.customerId || undefined,
    couponId: values.couponId || undefined,
    currency: values.currency,
    addons: values.product?.addons?.map((addon) => ({
      productId: addon.productId,
      unitCount: addon.unitCount || 0,
    })),
  });

  const createSubscription = async (params: CreateSubscriptionBody) => {
    try {
      setIsLoading(true);

      if (!params.customerId || !params.product) {
        return;
      }

      const subscription = await subscriptionsClient
        .createSubscription({
          body: {
            customerId: params.customerId,
            paymentMethodId: params.paymentMethodId,
            product: params.product,
            couponId: params.couponId,
            currency: params.currency,
          },
          allowTrialsRenewal: true,
          returnRelatedCoupon: true,
          returnRelatedNextPhase: true,
          returnRelatedPlan: true,
          returnRelatedProductInstance: true,
          returnRelatedScheduledUpdate: true,
        })
        .then((response) => response.data);

      notification.success({
        message: "Successfully created the subscription.",
      });
      props.close(subscription);
    } catch (error: unknown) {
      showErrorNotification(error);

      props.close();
    } finally {
      setIsLoading(false);
    }
  };

  const updateSubscription = async (params: UpdateSubscriptionBody) => {
    try {
      setIsLoading(true);

      if ((!params.planId && !params.product) || !props.subscription?.id) {
        return;
      }

      const subscription = await subscriptionsClient
        .updateSubscription({
          subscriptionId: props.subscription?.id,
          body: {
            paymentMethodId: params.paymentMethodId,
            product: params.product,
            couponId: params.couponId,
            force: params.force,
            source: SubscriptionUpdateSource.Admin,
          },
          returnRelatedCoupon: true,
          returnRelatedNextPhase: true,
          returnRelatedPlan: true,
          returnRelatedProductInstance: true,
          returnRelatedScheduledUpdate: true,
        })
        .then((response) => response.data);

      notification.success({
        message: "Successfully upgraded the subscription.",
      });
      props.close(subscription);
    } catch (error: unknown) {
      showErrorNotification(error);

      props.close();
    } finally {
      setIsLoading(false);
    }
  };

  const createSubscriptionAfterTrial = async (
    params: CreateSubscriptionBody,
  ) => {
    if (
      !params.customerId ||
      !params.product ||
      !props.subscription ||
      !props.subscription.isTrial
    ) {
      return;
    }
    try {
      setIsLoading(true);

      await subscriptionsClient.cancelSubscription({
        subscriptionId: props.subscription.id,
        cancelNow: true,
      });

      const { data: subscription } =
        await subscriptionsClient.createSubscription({
          body: {
            customerId: params.customerId,
            paymentMethodId: params.paymentMethodId,
            product: params.product,
            couponId: params.couponId,
            currency: params.currency,
          },
          allowTrialsRenewal: true,
          returnRelatedCoupon: true,
          returnRelatedNextPhase: true,
          returnRelatedPlan: true,
          returnRelatedProductInstance: true,
          returnRelatedScheduledUpdate: true,
        });

      notification.success({
        message: "Successfully created the subscription.",
      });
      props.close(subscription);
    } catch (error: unknown) {
      showErrorNotification(error);

      props.close();
    } finally {
      setIsLoading(false);
    }
  };

  const setCustomerAllowNoPaymentMethod = async (allow: boolean) => {
    try {
      setIsLoading(true);

      if (!customer) {
        return;
      }

      const { data } = await customersClient.updateCustomer({
        customerId: customer.id,
        body: {
          companyName: customer.companyName,
          email: customer.email,
          addressLine1: customer.addressLine1 || undefined,
          addressLine2: customer.addressLine2 || undefined,
          city: customer.city || undefined,
          country: (customer.country as CountryCode) || undefined,
          phone: customer.phone || undefined,
          postalCode: customer.postalCode || undefined,
          state: customer.state || undefined,
          taxExempt: customer.taxExempt as TaxExemptEnum,
          allowNoPaymentMethod: allow,
        },
      });

      notification.success({
        message: "Successfully updated the customer.",
      });
      setCustomer(data);
    } catch (error: unknown) {
      showErrorNotification(error);
      props.close();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width="50%"
      open
      onCancel={() => props.close()}
      okText={
        props.subscription?.id ? "Upgrade subscription" : "Create subscription"
      }
      onOk={() => handleSubmit()}
      okButtonProps={{
        disabled: !isValid,
        loading: isLoading,
      }}
      footer={[
        <Button key="back" onClick={() => props.close()}>
          Return
        </Button>,
        props.subscription?.isTrial ? (
          <Popconfirm
            key="submit"
            title="Current subscription is a trial. It will be canceled first. Are you sure ?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleSubmit()}
            okButtonProps={{
              loading: isLoading,
            }}
            disabled={!isValid || isLoading}
          >
            <Button
              key="submit"
              type="primary"
              disabled={!isValid || isLoading}
            >
              Submit
            </Button>
            ,
          </Popconfirm>
        ) : (
          <Button
            type="primary"
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={!isValid}
          >
            Submit
          </Button>
        ),
      ]}
    >
      <Tabs defaultActiveKey="products">
        <Tabs.TabPane
          tab="Products"
          key="products"
          disabled={
            props.subscription && props.subscription.type !== PlanType.Modules
          }
        >
          <Descriptions title="Details" size="default"></Descriptions>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="Customer">
              <Select
                loading={isLoadingCustomer}
                value={values.customerId || undefined}
                defaultValue={values.customerId || undefined}
                onChange={(customerId) =>
                  setValues({
                    ...values,
                    customerId,
                  })
                }
              >
                <Select.Option
                  value={customer?.id ?? ""}
                  key={customer?.id ?? ""}
                >
                  {customer?.email}
                </Select.Option>
              </Select>
            </Form.Item>
            {values.customerId &&
            !values.allowNoPaymentMethod &&
            !values.paymentMethodId ? (
              <Form.Item label="Allow offline payments">
                <Checkbox
                  disabled={isLoading}
                  checked={values.allowNoPaymentMethod}
                  onChange={(e) =>
                    setCustomerAllowNoPaymentMethod(e.target.checked)
                  }
                />
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item label="Payment Method">
              <Select
                loading={isLoadingCards || isLoadingSepaDebit}
                value={values.paymentMethodId || undefined}
                disabled={!customer}
                onChange={(option) =>
                  setValues((values) => ({
                    ...values,
                    paymentMethodId: [...cards, ...sepaDebits].find(
                      ({ id }) => id === option,
                    )?.id,
                  }))
                }
              >
                {sepaDebits.map((sepaDebit) => (
                  <Select.Option value={sepaDebit.id} key={sepaDebit.id}>
                    SEPA **** {sepaDebit.sepa_debit!.last4}
                  </Select.Option>
                ))}
                {cards.map((card) => (
                  <Select.Option value={card.id} key={card.id}>
                    {card.card!.brand?.toUpperCase()} **** {card.card!.last4}{" "}
                    {card.card!.expMonth}/{card.card!.expYear}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Product">
              <Select
                showSearch
                value={values.product?.productId}
                searchValue={nameIncludes}
                onSearch={setNameIncludes}
                filterOption={false}
                allowClear
                onClear={() => {
                  setProduct(undefined);
                  setNameIncludes(undefined);
                }}
                loading={isLoadingProducts}
                onChange={(option) => {
                  const product = products.find(({ id }) => id === option);
                  if (product) {
                    setProduct(product);
                    setValues((values) => ({
                      ...values,
                      planId: undefined,
                      product: {
                        ...values.product,
                        productId: option as string,
                        unitCount: values.product?.unitCount || 0,
                        addons: [],
                      },
                      couponId: product.defaultCoupon?.id,
                    }));
                  }
                }}
              >
                {products.map((product, index) => (
                  <Select.Option value={product.id} key={product.id}>
                    {index} - {product.description || product.name} (
                    {product.visibility} - Every {product.billingInterval}{" "}
                    {product.billingIntervalUnit})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Currency">
              <Select
                value={values.currency}
                allowClear
                onClear={() => setValues({ ...values, currency: undefined })}
                disabled={
                  !values.product?.productId ||
                  (!!props.subscription && !props.subscription.isTrial)
                }
                onChange={(currency) => setValues({ ...values, currency })}
              >
                {products
                  .find(({ id }) => id === values.product?.productId)
                  ?.prices?.map(({ currency }) => currency)
                  .map((currency) => (
                    <Select.Option value={currency} key={currency}>
                      {currency.toUpperCase()}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={`SKUs (${productCount} currently active)`}>
              <Input
                type="number"
                defaultValue={values.product?.unitCount || undefined}
                value={values.product?.unitCount || undefined}
                onChange={(e) =>
                  setValues({
                    ...values,
                    planId: undefined,
                    product: {
                      ...values.product,
                      productId: values.product?.productId || "",
                      unitCount: parseInt(e.target.value),
                      addons: (values.product?.addons || []).map((addon) => ({
                        ...addon,
                        unitCount: parseInt(e.target.value),
                      })),
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Addons" name="addons">
              <Space direction="vertical" size="large">
                {product &&
                  (product?.addons || []).map((addon) => (
                    <OrganizationSubscriptionEditAddon
                      key={addon.id}
                      addon={addon}
                      product={values.product}
                      instance={values.product?.addons?.find(
                        ({ productId }) => productId === addon.id,
                      )}
                      add={(params) =>
                        setValues({
                          ...values,
                          product: {
                            ...(values.product || {
                              unitCount: 0,
                              productId: product.id,
                            }),
                            addons: [
                              ...(values?.product?.addons?.filter(
                                (a) => a.productId !== addon.id,
                              ) || []),
                              {
                                productId: addon.id,
                                unitCount: params.unitCount,
                                targets: params.targets,
                              },
                            ],
                          },
                        })
                      }
                      remove={() =>
                        setValues({
                          ...values,
                          product: {
                            ...(values.product || {
                              unitCount: 0,
                              productId: product.id,
                            }),
                            addons: values?.product?.addons?.filter(
                              (a) => a.productId !== addon.id,
                            ),
                          },
                        })
                      }
                      update={(params) =>
                        setValues({
                          ...values,
                          product: {
                            ...(values.product || {
                              unitCount: 0,
                              productId: product.id,
                            }),
                            addons: values?.product?.addons?.map((a) =>
                              a.productId === addon.id
                                ? {
                                    productId: addon.id,
                                    unitCount: params.unitCount,
                                    targets: params.targets,
                                  }
                                : a,
                            ),
                          },
                        })
                      }
                    />
                  ))}
              </Space>
            </Form.Item>
            <Form.Item label="Coupon">
              <Select
                value={values.couponId || undefined}
                allowClear
                onClear={() => {
                  setValues((values) => ({
                    ...values,
                    couponId: undefined,
                  }));
                }}
                loading={isLoadingCoupons}
                onChange={(option) => {
                  setValues((values) => ({
                    ...values,
                    couponId: option as string,
                  }));
                }}
              >
                {coupons.map((coupon) => (
                  <Select.Option value={coupon.id} key={coupon.id}>
                    {coupon.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Force apply">
              <Checkbox
                checked={values.force}
                onChange={(e) =>
                  setValues({ ...values, force: e.target.checked })
                }
              />
              {values.force ? (
                <div>
                  <Typography.Text type="danger">
                    Checking that box will prevent any proration when
                    upgrading/downgrading the subscription. Please make sure
                    that is the behaviour you expect. Usually you should not
                    check the box unless you are downselling a subscription.
                  </Typography.Text>
                </div>
              ) : null}
            </Form.Item>
          </Form>
          {instance ? (
            <Descriptions bordered title="Summary" size="default">
              <Descriptions.Item label="Base" span={3}>
                {((instance.price || 0) / 100).toFixed(2)}
                {currencyToSymbol(values.currency || Currency.Eur)}
              </Descriptions.Item>
              {instance.addons?.map((addon) => (
                <Descriptions.Item
                  key={addon.product?.name}
                  label={`${addon.product?.name}`}
                  span={3}
                >
                  {((addon.price || 0) / 100).toFixed(2)}
                  {currencyToSymbol(values.currency || Currency.Eur)}
                </Descriptions.Item>
              ))}
              {coupons?.find(({ id }) => id === values?.couponId)
                ?.amountOff && (
                <Descriptions.Item label="Discount" span={3}>
                  {coupons
                    ?.find(({ id }) => id === values?.couponId)
                    ?.amountOff?.toFixed(2)}
                  {currencyToSymbol(values.currency || Currency.Eur)}
                </Descriptions.Item>
              )}
              {coupons?.find(({ id }) => id === values?.couponId)
                ?.percentOff && (
                <Descriptions.Item label="Discount" span={3}>
                  {coupons
                    ?.find(({ id }) => id === values?.couponId)
                    ?.percentOff?.toFixed(2)}
                  %
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Total" span={3}>
                {((instance.priceWithAddons || 0) / 100).toFixed(2)}
                {currencyToSymbol(values.currency || Currency.Eur)}
              </Descriptions.Item>
            </Descriptions>
          ) : null}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
