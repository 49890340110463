import { useMemo, useState } from "react";

import { Select } from "antd";

import { useListOrganizationMemberships } from "../hooks/useListOrganizationMemberships";
import { useSearchAccountsByEmail } from "../hooks/useSearchAccountsByEmail";

type OrganizationEmailsSearchProps = {
  organizationId?: string;
  selectedEmails: string[];
  setSelectedEmails: (emails: string[]) => void;
};

export const OrganizationEmailsSearch = ({
  organizationId,
  selectedEmails,
  setSelectedEmails,
}: OrganizationEmailsSearchProps) => {
  const [search, setSearch] = useState<string>("");

  const { data: organizationMembershipsData } =
    useListOrganizationMemberships(organizationId);
  const { accounts: accountsFromEmailSearch } =
    useSearchAccountsByEmail(search);

  const emailOptions = useMemo(() => {
    const accounts = organizationId
      ? (organizationMembershipsData?.data.flatMap((membership) =>
          membership.account ? [membership.account] : [],
        ) ?? [])
      : accountsFromEmailSearch;

    return accounts
      .filter(Boolean)
      .filter(({ email }) => email.includes(search))
      .map(({ email }) => ({ label: email, value: email, key: email }));
  }, [
    accountsFromEmailSearch,
    organizationId,
    organizationMembershipsData,
    search,
  ]);

  const clearSearch = () => setSearch("");

  const onSelect = (value: string) => {
    clearSearch();
    setSelectedEmails([...selectedEmails, value]);
  };

  const onDeselect = (value: string) =>
    setSelectedEmails(selectedEmails.filter((email) => email !== value));

  const handleClear = () => {
    clearSearch();
    setSelectedEmails([]);
  };

  return (
    <Select
      allowClear
      showSearch
      searchValue={search}
      onSearch={setSearch}
      mode="multiple"
      style={{ width: "100%" }}
      placeholder={
        organizationId
          ? "Search for email(s) in the selected organization"
          : "search for Omi account emails"
      }
      filterOption={false}
      value={selectedEmails}
      options={emailOptions}
      onSelect={onSelect}
      onClear={handleClear}
      onChange={setSelectedEmails}
      onDeselect={onDeselect}
      onBlur={clearSearch}
    />
  );
};
