import type { FC } from "react";
import { useEffect, useState } from "react";

import { List, Modal, Pagination } from "antd";

import type {
  ListSceneTemplatesV2OrderByEnum,
  ListSceneTemplatesV2VisibilityEnum,
  SceneTemplate,
  SceneTemplateCollection,
  SceneTemplateTag,
  UpdateSceneTemplateBody,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";

import { SceneCard } from "./TemplateCard";

type Props = {
  mode?: "select" | "view";
  onSelect?: (id: string) => void;
  selectedTemplatesId?: string[];
  filter?: string;
  collectionFilter?: string;
  visibilityFilter?: ListSceneTemplatesV2VisibilityEnum;
  orderBy?: ListSceneTemplatesV2OrderByEnum;
  collections: SceneTemplateCollection[];
  tags: SceneTemplateTag[];
};

export const TemplateList: FC<Props> = (props) => {
  const [sceneTemplates, setSceneTemplates] = useState<SceneTemplate[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);

  const sceneTemplatesClient = useClientsStore(
    (state) => state.sceneTemplatesClient,
  );

  useEffect(() => {
    const getSceneTemplates = async () =>
      sceneTemplatesClient
        .listSceneTemplatesV2({
          returnRelatedTags: true,
          returnRelatedCollections: true,
          returnRelatedCapabilities: true,
          returnRelatedNameTextContent: true,
          visibility: props.visibilityFilter && [props.visibilityFilter],
          nameIncludes: props.filter,
          collectionPath: props.collectionFilter,
          orderBy: props.orderBy,
          page,
          pageSize,
        })
        .then(({ data: { count, data } }) => {
          setCount(count);
          setSceneTemplates(data);
        });

    getSceneTemplates();
  }, [
    sceneTemplatesClient,
    setSceneTemplates,
    page,
    pageSize,
    props.filter,
    props.orderBy,
    props.collectionFilter,
    props.visibilityFilter,
  ]);

  const updateScene = async (id: string, body: UpdateSceneTemplateBody) => {
    try {
      const { data } = await sceneTemplatesClient.updateSceneTemplate({
        sceneTemplateId: id,
        body,
        returnRelatedCollections: true,
        returnRelatedTags: true,
        returnRelatedCapabilities: true,
      });

      setSceneTemplates(
        sceneTemplates.map((template) =>
          template.id === id ? data : template,
        ),
      );
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while updating the template",
        content: `${error}`,
      });
    }
  };

  const deleteScene = async (id: string) => {
    try {
      await sceneTemplatesClient.deleteSceneTemplate({ sceneTemplateId: id });

      setSceneTemplates(
        sceneTemplates.filter((template) => template.id !== id),
      );

      Modal.success({
        title: "Success",
        content: "The template was successfully deleted",
      });
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while deleting the template",
        content: `${error}`,
      });
    }
  };

  return (
    <>
      <List
        grid={{
          gutter: 4,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 9,
        }}
        dataSource={sceneTemplates}
        renderItem={(template) => (
          <List.Item>
            <SceneCard
              {...props}
              mode={props.mode}
              onSelect={props.onSelect}
              selectedTemplatesId={props.selectedTemplatesId}
              key={template.id}
              sceneTemplate={template}
              deleteSceneTemplate={() => deleteScene(template.id!)}
              updateSceneTemplate={updateScene}
              collections={props.collections}
              tags={props.tags}
            />
          </List.Item>
        )}
      />
      <Pagination
        style={{ marginTop: 20 }}
        key={count}
        current={page}
        onChange={setPage}
        total={count}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger
      />
    </>
  );
};
