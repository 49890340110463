import type { FC } from "react";

import { Select } from "antd";

import type { ObjectVisibility } from "@omi-lab/atlas-typescript";

import { useSearchObjects } from "../../Organizations/hooks/useSearchProducts";

interface Props {
  placeholder?: string;
  showId?: boolean;
  orgId?: string;
  value?: string[];
  visibilityIn?: ObjectVisibility[];
  onSelect: (id: string) => void;
  onDeselect: (id: string) => void;
  onClear: () => void;
}

export const ObjectSearch: FC<Props> = (props) => {
  const { objects, setSearch } = useSearchObjects(
    props.orgId,
    props.visibilityIn,
  );

  return (
    <Select
      mode="multiple"
      allowClear
      showSearch
      style={{ width: "100%" }}
      placeholder={props.placeholder ?? "Search for objects"}
      filterOption={false}
      defaultValue={props.value || []}
      onSearch={setSearch}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      onClear={props.onClear}
    >
      {objects.map((object) => (
        <Select.Option value={object.id} key={object.id}>
          {object.name} {props.showId ? `(${object.id})` : null}
        </Select.Option>
      ))}
    </Select>
  );
};
