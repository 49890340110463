import { useEffect, useState } from "react";

import { Modal } from "antd";

import type {
  ScenePreviewStatus,
  SceneTemplateCustomPreview,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";

export const useListSceneTemplateCustomPreviews = (params: {
  status?: ScenePreviewStatus;
  workflowId?: string;
  organizationId?: string;
  sceneTemplateId?: string;
  page?: number;
  pageSize?: number;
}) => {
  const [previews, setPreviews] = useState<SceneTemplateCustomPreview[]>([]);
  const [page, setPage] = useState(params.page || 1);
  const [pageSize, setPageSize] = useState(params.page || 30);
  const [count, setCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const client = useClientsStore(
    (state) => state.sceneTemplateCustomPreviewClient,
  );

  useEffect(() => {
    const listSceneTemplateCustomPreviews = async () => {
      try {
        setIsLoading(true);

        const data = (
          await client.listSceneTemplateCustomPreviews({
            pageSize,
            page,
            status: params.status,
            workflowId: params.workflowId,
            organizationId: params.organizationId,
            sceneTemplateId: params.sceneTemplateId,
            returnRelatedSceneTemplate: true,
          })
        ).data;

        setPreviews(data.data);
        setCount(data.count);
      } catch {
        Modal.error({
          content: "An error occured while listing the previews.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    listSceneTemplateCustomPreviews();
  }, [
    client,
    setPreviews,
    params.status,
    params.workflowId,
    params.organizationId,
    params.sceneTemplateId,
    page,
    pageSize,
  ]);

  return {
    previews,
    setPreviews,
    isLoading,
    page,
    setPage,
    pageSize,
    setPageSize,
    count,
  };
};
