import type { FC } from "react";
import { useEffect, useState } from "react";

import { LinkOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Input,
  List,
  message,
  Pagination,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import { format } from "date-fns";

import type {
  Organization,
  UpdateOrganizationBody,
} from "@omi-lab/ceos-typescript";

import { showErrorMessage } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { OrganizationActions } from "../components/OrganizationActions";
import { OrganizationInvitations } from "../components/OrganizationInvitations";
import { OrganizationLogo } from "../components/OrganizationLogo";
import { OrganizationMembers } from "../components/OrganizationMembers";
import { OrganizationProductsDetails } from "../components/OrganizationProductsDetails";
import { OrganizationQuotesDetails } from "../components/OrganizationQuotesDetails";
import { OrganizationSubscriptionsDetails } from "../components/OrganizationSubscriptionsDetails";
import { OrganizationSubscriptionStatus } from "../components/OrganizationSubscriptionStatus";
import { useGetProductCount } from "../hooks/useGetProductCount";

type OrganizationOrderByOptionsKeys =
  | "created_at_desc"
  | "created_at_asc"
  | "updated_at_desc"
  | "updated_at_asc";
type OrganizationOrderBy = "name" | "created_at" | "updated_at";
type OrganizationOrder = "desc" | "asc";

const OrganizationsOrderByOptions: Record<
  OrganizationOrderByOptionsKeys,
  {
    label: string;
    order_by: OrganizationOrderBy;
    order: OrganizationOrder;
  }
> = {
  created_at_desc: {
    label: "Created at (Descending)",
    order_by: "created_at",
    order: "desc",
  },
  created_at_asc: {
    label: "Created at (Ascending)",
    order_by: "created_at",
    order: "asc",
  },
  updated_at_desc: {
    label: "Updated at (Descending)",
    order_by: "updated_at",
    order: "desc",
  },
  updated_at_asc: {
    label: "Updated at (Ascending)",
    order_by: "updated_at",
    order: "asc",
  },
};

export const Organizations: FC = () => {
  const [organizations, setOrganizations] = useState([] as Organization[]);
  const [filter, setFilter] = useState("");
  const [organizationIds, setOrganizationIds] = useState("");
  const [email, setEmail] = useState("");
  const [orderBy, setOrderBy] = useState<
    | "created_at_desc"
    | "created_at_asc"
    | "updated_at_desc"
    | "updated_at_asc"
    | undefined
  >();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const organizationsClient = useClientsStore(
    (state) => state.organizationsClient,
  );

  useEffect(() => {
    const listOrganizations = async () =>
      organizationsClient
        .listOrganizations({
          returnRelatedTeams: true,
          returnRelatedOrganizations: true,
          returnRelatedMemberships: true,
          page,
          pageSize,
          nameIncludes: filter,
          email,
          ids: organizationIds.split(","),
          order: orderBy && OrganizationsOrderByOptions[orderBy].order,
          orderBy: orderBy && OrganizationsOrderByOptions[orderBy].order_by,
        })
        .then((response) => {
          if (response.data.length >= pageSize) {
            setPageCount(page + 1);
          }
          setOrganizations(response.data);
        });

    listOrganizations();
  }, [
    organizationsClient,
    setOrganizations,
    page,
    pageSize,
    filter,
    orderBy,
    email,
    organizationIds,
  ]);

  const deleteOrganization = async (id: string) => {
    try {
      await organizationsClient.deleteOrganization({
        organizationId: id,
      });
      message.success("The organization was successfully deleted");

      setOrganizations(
        organizations.filter((organization) => organization.id !== id),
      );
    } catch (error: unknown) {
      showErrorMessage(error);
    }
  };

  const updateOrganization = async (
    id: string,
    update: UpdateOrganizationBody,
  ) => {
    try {
      const { data } = await organizationsClient.updateOrganization({
        organizationId: id,
        body: update,
        returnRelatedMemberships: false,
        returnRelatedOrganizations: false,
        returnRelatedTeams: false,
      });
      message.success("The organization was successfully updated");

      setOrganizations(
        organizations.map((organization) =>
          organization.id === id ? data : organization,
        ),
      );
    } catch (error: unknown) {
      showErrorMessage(error);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <List grid={{ gutter: 16 }}>
        <List.Item>
          <Row gutter={16} justify="space-between">
            <Col span={6}>
              <Input
                placeholder="Search for a organization"
                prefix={
                  <SearchOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                onChange={(e) => setFilter(e.target.value)}
              />
            </Col>
            <Col span={11}>
              <Input
                placeholder="Paste IDs as <id1>,<id2>..."
                prefix={
                  <SearchOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                onChange={(e) => setOrganizationIds(e.target.value)}
              />
            </Col>
            <Col span={3}>
              <Input
                placeholder="Search by email"
                prefix={
                  <SearchOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col span={4}>
              <Select
                style={{ width: "100%" }}
                placeholder="Sort by"
                onChange={(value) =>
                  setOrderBy(value as OrganizationOrderByOptionsKeys)
                }
              >
                {Object.entries(OrganizationsOrderByOptions).map(
                  ([key, value]) => (
                    <Select.Option key={key} value={key}>
                      {value.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Col>
          </Row>
        </List.Item>
        <List.Item>
          <Table
            columns={[
              {
                title: "",
                dataIndex: "logo",
                key: "logo",
                render: (logo) => <OrganizationLogo fileId={logo} />,
                width: "5%",
              },
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
                render: (name) => <p>{name}</p>,
                width: "5%",
              },
              {
                title: "ID",
                dataIndex: "id",
                key: "id",
                render: (id) => <p>{id}</p>,
                width: "10%",
              },
              {
                title: "Onboarding",
                key: "onboarding",
                render: (_, organization) => (
                  <OrganizationSubscriptionStatus
                    organization={organization}
                    setWasOnboarded={(wasOnboarded: boolean) =>
                      updateOrganization(organization.id, {
                        name: organization.name,
                        logo: organization.logo,
                        rootOrganizationID: organization.rootOrganizationId,
                        wasOnboarded,
                      })
                    }
                  />
                ),
                width: "55%",
              },
              {
                title: "Created at",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (createdAt) => (
                  <p>{format(new Date(createdAt), "yyyy-MM-dd")}</p>
                ),
                width: "5%",
              },
              {
                title: "Updated at",
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (updatedAt) => (
                  <p>{format(new Date(updatedAt), "yyyy-MM-dd")}</p>
                ),
                width: "5%",
              },
              {
                title: "Actions",
                dataIndex: "actions",
                key: "actions",
                render: (_, record) => (
                  <OrganizationActions
                    organization={record}
                    deleteOrganization={deleteOrganization}
                  />
                ),
                width: "15%",
              },
            ]}
            dataSource={organizations.map((organization) => ({
              ...organization,
              key: organization.id,
            }))}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <OrganizationTabs organization={record} />
              ),
            }}
          />
          <Divider />
          <Pagination
            current={page}
            onChange={setPage}
            total={pageCount * pageSize}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            showSizeChanger
          />
        </List.Item>
      </List>
    </Col>
  );
};

interface OrganizationTabsProps {
  organization: Organization;
}

export const OrganizationTabs: FC<OrganizationTabsProps> = ({
  organization: { id: organizationId, websiteUrl },
}) => {
  const { productCount, setProductCount } = useGetProductCount({
    organizationId,
  });

  return (
    <>
      {websiteUrl ? (
        <>
          <p>
            Website url{" "}
            <LinkOutlined
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />{" "}
            :{" "}
            <a target="_blank" rel="noopener noreferrer" href={websiteUrl}>
              {websiteUrl}
            </a>
          </p>
          <Divider />
        </>
      ) : null}
      <Tabs defaultActiveKey="members">
        <Tabs.TabPane tab="Members" key="members">
          <OrganizationMembers organizationId={organizationId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Invitations" key="invitations">
          <OrganizationInvitations organizationId={organizationId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Subscriptions" key="subscription">
          <OrganizationSubscriptionsDetails organizationId={organizationId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Quotes" key="quotes">
          <OrganizationQuotesDetails organizationId={organizationId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Products (${productCount})`} key="products">
          <OrganizationProductsDetails
            organizationId={organizationId}
            setProductCount={setProductCount}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
