import type { FC } from "react";

import { Select } from "antd";

import { SceneTemplateCapabilities } from "@omi-lab/atlas-typescript";

import { useSearchSceneTemplates } from "../hooks/useSearchSceneTemplates";

interface Props {
  onSelect: (id: string) => void;
  onDeselect: (id: string) => void;
  onClear: () => void;
}

export const SceneTemplateSearchMultiple: FC<Props> = (props) => {
  const { sceneTemplates, setSearch } = useSearchSceneTemplates({
    capability: SceneTemplateCapabilities.CustomPreview,
  });

  return (
    <Select
      allowClear
      showSearch
      mode="tags"
      style={{ width: "100%" }}
      placeholder="Search for scene templates"
      filterOption={false}
      defaultValue={[]}
      onSearch={setSearch}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      onClear={props.onClear}
    >
      {sceneTemplates.map((template) => (
        <Select.Option value={template.id} key={template.id}>
          {template.name}
        </Select.Option>
      ))}
    </Select>
  );
};
