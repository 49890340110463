import type { FC } from "react";
import { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import {
  Card,
  Image,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";

import type {
  SkyBox,
  SkyBoxCollection,
  SkyBoxTag,
  UpdateSkyBoxBody,
} from "@omi-lab/atlas-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";

type Props = {
  skyBox: SkyBox;
  deleteSkyBox: (skyBoxId: string) => void;
  updateSkyBox: (skyBoxId: string, body: UpdateSkyBoxBody) => void;
  collections: SkyBoxCollection[];
  tags: SkyBoxTag[];
};

export const SkyBoxCard: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<ModelFile>();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [filesClient] = useClientsStore((state) => [state.filesClient]);

  useEffect(() => {
    if (!props.skyBox.thumbnailFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.skyBox.thumbnailFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, props.skyBox.thumbnailFileId]);

  const deleteSkyBox = async () => {
    try {
      setIsDeleting(true);
      await props.deleteSkyBox(props.skyBox.id);
    } finally {
      setIsDeleting(false);
    }
  };

  const updateSkyBox = async (body: UpdateSkyBoxBody) => {
    try {
      setIsUpdating(true);
      await props.updateSkyBox(props.skyBox.id, body);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card
      hoverable={false}
      actions={[
        <Popconfirm
          title="Are you sure to delete this skyBox?"
          onConfirm={deleteSkyBox}
          okText="Yes"
          okButtonProps={{ loading: isDeleting }}
          cancelText="No"
          key="delete"
        >
          <DeleteOutlined
            key="delete"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </Popconfirm>,
      ]}
    >
      <Skeleton loading={isLoading} avatar active>
        <Space
          style={{ width: "100%", height: 430, overflowY: "scroll" }}
          direction="vertical"
          size="large"
        >
          <Image src={thumbnail?.url ?? ""} />
          <Card.Meta
            title={
              <Tooltip title={props.skyBox.name}>
                <span>{props.skyBox.name}</span>
              </Tooltip>
            }
          />
          <Select
            mode="tags"
            placeholder="Select tags"
            loading={isUpdating}
            style={{ width: "100%" }}
            showArrow
            maxTagCount={1}
            value={(props.skyBox.tags || []).map((tag) => tag.name)}
            onSelect={(key) =>
              updateSkyBox({
                tags: [
                  ...(props.skyBox.tags || [])
                    .filter(({ name }) => name !== key)
                    .map((tag) => tag.name),
                  key,
                ],
              })
            }
            onDeselect={(key) =>
              updateSkyBox({
                tags: (props.skyBox.tags || [])
                  .filter(({ name }) => name !== key)
                  .map((tag) => tag.name),
              })
            }
          >
            {props.tags.map((tag) => (
              <Select.Option key={tag.name} value={tag.name}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
          <Select
            mode="multiple"
            placeholder="Select collections"
            loading={isUpdating}
            style={{ width: "100%" }}
            showArrow
            maxTagCount={1}
            value={(props.skyBox.collections || []).map(
              (collection) => collection.path,
            )}
            onSelect={(key) =>
              updateSkyBox({
                collections: [
                  ...(props.skyBox.collections || []).filter(
                    ({ path }) => path !== key,
                  ),
                  props.collections.find(({ path }) => path === key)!,
                ],
              })
            }
            onDeselect={(key) =>
              updateSkyBox({
                collections: (props.skyBox.collections || []).filter(
                  ({ path }) => path !== key,
                ),
              })
            }
          >
            {props.collections.map((collection) => (
              <Select.Option key={collection.path} value={collection.path}>
                {collection.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Skeleton>
    </Card>
  );
};
