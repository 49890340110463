import { useEffect, useState } from "react";

import type { Customer } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetCustomer(organizationId?: string) {
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [customersClient] = useClientsStore((store) => [store.customersClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!organizationId) {
          return;
        }
        const customers = (
          await customersClient.listCustomers({
            organizationId,
          })
        ).data;
        if (customers.length > 0) {
          setCustomer(customers[0]);
        }
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationId, customersClient]);

  return { customer, setCustomer, isLoading };
}
