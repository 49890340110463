import { useEffect, useState } from "react";

import type { LightSet } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetLightSet(id: string) {
  const [lightSet, setlightSet] = useState<LightSet | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [lightSetsClient] = useClientsStore((store) => [store.lightSetsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const lightSet = (
          await lightSetsClient.getLightSet({
            lightSetId: id,
          })
        ).data;

        setlightSet(lightSet);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, lightSetsClient]);

  return { lightSet, isLoading };
}
