import { useEffect, useState } from "react";

import type { SkyBox } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetSkybox(id?: string) {
  const [skyBox, setSkyBox] = useState<SkyBox | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [skyBoxsClient] = useClientsStore((store) => [store.skyBoxesClient]);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      setIsLoading(true);
      try {
        const skyBox = (
          await skyBoxsClient.getSkyBox({
            skyBoxId: id || "",
          })
        ).data;

        setSkyBox(skyBox);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, skyBoxsClient]);

  return { skyBox, isLoading };
}
