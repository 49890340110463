import * as Yup from "yup";

import {
  Currency,
  IntervalUnit,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

export const ValidationScheme = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name should be at least 3 characters long")
    .required("A name is required"),
  slug: Yup.string()
    .min(3, "Slug should be at least 3 characters long")
    .required("A slug is required"),
  visibility: Yup.string()
    .oneOf(Object.values(ProductVisibility))
    .required("A visibility is required"),
  type: Yup.string()
    .oneOf(Object.values(ProductType))
    .required("A type is required"),
  billingInterval: Yup.number()
    .min(1, "Billing interval can not be lower than 0")
    .required("A billing interval is required"),
  billingIntervalUnit: Yup.string()
    .oneOf(Object.values(IntervalUnit))
    .required("A billing interval unit is required"),
  commitmentInterval: Yup.number()
    .min(1, "Commitment interval can not be lower than 0")
    .required("A commitment interval is required"),
  commitmentIntervalUnit: Yup.string()
    .oneOf(Object.values(IntervalUnit))
    .required("A commitment interval unit is required"),
  features: Yup.array(Yup.string()).required("A list of features is required."),
  addons: Yup.array(
    Yup.object()
      .shape({
        name: Yup.string()
          .min(3, "Name should be at least 3 characters long")
          .required("A name is required"),
        slug: Yup.string()
          .min(3, "Slug should be at least 3 characters long")
          .required("A slug is required"),
        type: Yup.string()
          .oneOf(Object.values(ProductType))
          .required("A type is required"),
        features: Yup.array(Yup.string()).required(
          "A list of features is required.",
        ),
        prices: Yup.array(
          Yup.object()
            .shape({
              currency: Yup.string().oneOf(Object.values(Currency)).required(),
              unitAmount: Yup.number().optional(),
              tiers: Yup.array(
                Yup.object()
                  .shape({
                    upTo: Yup.number().nullable(),
                    unitAmount: Yup.number().min(0).required(),
                  })
                  .required(),
              )
                .nullable()
                .optional(),
            })
            .required(),
        )
          .min(1)
          .required(),
      })
      .required(),
  ).required(),
  prices: Yup.array(
    Yup.object()
      .shape({
        currency: Yup.string().oneOf(Object.values(Currency)).required(),
        unitAmount: Yup.number().nullable(),
        tiers: Yup.array(
          Yup.object()
            .shape({
              upTo: Yup.number().nullable(),
              unitAmount: Yup.number().min(0).required(),
            })
            .required(),
        )
          .min(1)
          .required(),
      })
      .required(),
  )
    .min(1)
    .required(),
});
