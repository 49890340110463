import type { FC } from "react";
import { useState } from "react";

import { Button, Col, notification, Row, Select, Table } from "antd";

import type {
  CreateProductAddonBody,
  Product,
  UpdateProductBody,
} from "@omi-lab/cresus-typescript";
import {
  PlanFeatureName,
  PlanVisibility,
  ProductType,
} from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";
import {
  productSlugToHumanReadable,
  productTypeToHumanReadable,
} from "src/utils/products";

import { useClientsStore } from "../../../store/clients";
import { CreateAddonModal } from "../components/CreateAddonModal";
import { useListProductAddons } from "../hooks";

interface Props {
  product: Product;
}

export const Addons: FC<Props> = (props) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const client = useClientsStore((store) => store.productsClient);

  const { addons, setAddons, isLoading } = useListProductAddons({
    productId: props.product.id,
  });

  const updateAddon = async (id: string, body: UpdateProductBody) => {
    try {
      setIsUpdating(true);
      await client
        .updateProduct({
          productId: id,
          body,
          returnRelatedAddons: true,
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        })
        .then(({ data }) =>
          setAddons(addons.map((addon) => (addon.id === id ? data : addon))),
        );
      notification.success({
        message: "The addon was successfully updated.",
        duration: 1,
      });
    } catch (error: unknown) {
      showErrorNotification(error, { duration: 1 });
    } finally {
      setIsUpdating(false);
    }
  };

  const createAddon = async (id: string, body: CreateProductAddonBody) => {
    try {
      setIsUpdating(true);

      const hasTiers = (addon: CreateProductAddonBody) =>
        (addon.prices[0]?.tiers?.length || 0) > 0;

      await client
        .createProductAddon({
          productId: id,
          body: {
            ...body,
            type: hasTiers(body) ? ProductType.SkuAddonTiered : body.type,
            prices: body.prices.map((price) => ({
              ...price,
              tiers: hasTiers(body) ? price.tiers! : undefined,
              unitAmount: !hasTiers(body) ? (price.unitAmount as number) : null,
            })),
          },
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        })
        .then(({ data }) => setAddons([...addons, data]));
      notification.success({
        message: "The addon was successfully created.",
        duration: 1,
      });
    } catch (error: unknown) {
      showErrorNotification(error, { duration: 1 });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Row gutter={16} justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            +
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={addons}
        pagination={false}
        loading={isLoading}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "15%",
            render: (_, record) => <p>{record.id}</p>,
          },
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            width: "10%",
            render: (_, record) => <p>{record.name}</p>,
          },
          {
            title: "Slug",
            key: "slug",
            dataIndex: "slug",
            width: "15%",
            render: (_, record) => (
              <p>{productSlugToHumanReadable(record.slug)}</p>
            ),
          },
          {
            title: "Type",
            key: "type",
            dataIndex: "type",
            width: "20%",
            render: (_, record) => (
              <p>{productTypeToHumanReadable(record.type!)}</p>
            ),
          },
          {
            title: "Billing",
            key: "billing",
            width: "15%",
            render: () => (
              <p>
                Every {props.product.billingInterval}{" "}
                {props.product.billingIntervalUnit}
              </p>
            ),
          },
          {
            title: "Visibility",
            key: "visibility",
            width: "15%",
            render: (_, record: Product) => (
              <Select
                value={record.visibility}
                onChange={(v) =>
                  updateAddon(record.id, {
                    visibility: v,
                  })
                }
              >
                {Object.values(PlanVisibility).map((visibility) => (
                  <Select.Option key={visibility} value={visibility}>
                    {visibility}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Features",
            key: "features",
            width: "10%",
            render: (_, record: Product) => (
              <Select
                style={{ minWidth: "200px" }}
                disabled={isUpdating}
                mode="multiple"
                value={(record.features || []).map(({ name }) => name)}
                maxTagCount={3}
                onSelect={(name) =>
                  updateAddon(record.id, {
                    features: (record.features || [])
                      .map((feature) => feature.name)
                      .concat(name),
                  })
                }
                onDeselect={(name) =>
                  updateAddon(record.id, {
                    features: (record.features || [])
                      .filter((feature) => feature.name !== name)
                      .map((feature) => feature.name),
                  })
                }
              >
                {Object.values(PlanFeatureName).map((name) => (
                  <Select.Option value={name} key={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
        ]}
      />
      <CreateAddonModal
        isOpen={isModalVisible}
        close={() => setIsModalVisible(false)}
        createAddon={createAddon}
        product={props.product}
        key={props.product.id}
      />
    </Col>
  );
};
