import type { FC } from "react";
import { useState } from "react";

import { Modal } from "antd";

import { useListSceneTemplateCustomPreviews } from "../hooks";

interface Props {
  workflowId: string;
  isVisible: boolean;
  close: () => void;
  rescheduleWorkflow: (id: string) => Promise<void>;
  hasBeenRescheduled: boolean;
}

export const TemplateCustomPreviewConfirmWorkflowReschedulingModal: FC<
  Props
> = (props) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const { count } = useListSceneTemplateCustomPreviews({
    workflowId: props.workflowId,
    page: 1,
    pageSize: 0,
  });

  const rescheduleWorkflow = async () => {
    try {
      setIsUpdating(true);

      await props.rescheduleWorkflow(props.workflowId);

      props.close();
    } catch {
      props.close();
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Modal
      okText="Resubmit"
      open={props.isVisible}
      okButtonProps={{
        loading: isUpdating,
      }}
      onOk={rescheduleWorkflow}
      onCancel={props.close}
      cancelButtonProps={{
        loading: isUpdating,
      }}
      style={{ width: "100%" }}
    >
      <p>
        Rescheduling {props.workflowId} will have impact on {count} previews.
      </p>
      {props.hasBeenRescheduled && (
        <p>Note that this workflow has already been rescheduled.</p>
      )}
    </Modal>
  );
};
