import { useEffect, useState } from "react";

import type { SystemAdministrator } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useListSystemAdministrators() {
  const [administrators, setAdministrators] = useState<SystemAdministrator[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const [systemAdministratorsClient] = useClientsStore((store) => [
    store.systemAdministratorsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await systemAdministratorsClient
          .listSystemAdministrators({
            returnRelatedAccount: true,
            pageSize: 100,
            page: 1,
          })
          .then((res) => setAdministrators(res.data));
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [systemAdministratorsClient]);

  return { administrators, setAdministrators, isLoading };
}
