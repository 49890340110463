import { useEffect, useState } from "react";

import type {
  PaymentMethod,
  PaymentMethodTypeEnum,
} from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useListPaymentMethods(
  customerId?: string,
  type?: PaymentMethodTypeEnum,
) {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [customersClient] = useClientsStore((store) => [store.customersClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!customerId || !type) {
          return;
        }
        setPaymentMethods(
          (
            await customersClient.listPaymentMethods({
              customerId,
              type,
            })
          ).data,
        );
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [customerId, type, customersClient]);

  return { paymentMethods, isLoading };
}
