import { useEffect, useState } from "react";

import type {
  SceneTemplate,
  SceneTemplateCapabilities,
} from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useSearchSceneTemplates(params: {
  capability?: SceneTemplateCapabilities;
}) {
  const [search, setSearch] = useState("");
  const [sceneTemplates, setSceneTemplates] = useState<SceneTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [sceneTemplatesClient] = useClientsStore((store) => [
    store.sceneTemplatesClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await sceneTemplatesClient
          .listSceneTemplates({
            nameIncludes: search,
            page: 1,
            pageSize: 30,
            capability: params.capability,
          })
          .then((response) => setSceneTemplates(response.data));
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [sceneTemplatesClient, search, params.capability]);

  return { sceneTemplates, search, setSearch, isLoading };
}
