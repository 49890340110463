import { useEffect, useState } from "react";

import type { ListOrganizationMembershipsV2ResponseBody } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useListOrganizationMemberships(organizationId?: string) {
  const [membershipsData, setMembershipsData] =
    useState<ListOrganizationMembershipsV2ResponseBody | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const organizationsClient = useClientsStore(
    (store) => store.organizationsClient,
  );

  useEffect(() => {
    (async () => {
      if (!organizationId) {
        return;
      }
      setIsLoading(true);
      try {
        const { data } =
          await organizationsClient.listOrganizationMembershipsV2({
            organizationId: organizationId,
            returnRelatedAccount: true,
          });

        setMembershipsData(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationId, organizationsClient]);

  return { data: membershipsData, isLoading };
}
