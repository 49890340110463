import type { FC } from "react";

import { Select } from "antd";

import { useSearchSceneTemplates } from "../hooks/useSearchSceneTemplates";

interface Props {
  onChange: (id: string) => void;
  onClear: () => void;
}

export const SceneTemplateSearch: FC<Props> = (props) => {
  const { sceneTemplates, setSearch } = useSearchSceneTemplates({});

  return (
    <Select
      allowClear
      showSearch
      style={{ width: "100%" }}
      placeholder="Search for scene templates"
      filterOption={false}
      defaultValue={undefined}
      onSearch={setSearch}
      onChange={props.onChange}
      onClear={props.onClear}
    >
      {sceneTemplates.map((template) => (
        <Select.Option value={template.id} key={template.id}>
          {template.name}
        </Select.Option>
      ))}
    </Select>
  );
};
