import { useEffect, useState } from "react";

import type { Product } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useListProducts(params: {
  nameIncludes?: string;
  page?: number;
  pageSize?: number;
}) {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [productsClient] = useClientsStore((store) => [store.productsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await productsClient.listProducts({
          nameIncludes: params.nameIncludes,
          visibility: ["public", "hidden"],
          page: params.page,
          pageSize: params.pageSize,
          returnRelatedFeatures: true,
          returnRelatedAddons: true,
          returnRelatedDefaultCoupon: true,
          returnRelatedPrices: true,
        });

        setProducts(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productsClient, params.nameIncludes, params.page, params.pageSize]);

  return { products, setProducts, isLoading, pageCount };
}
