import { useEffect, useState } from "react";

import type { Product } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetProduct(id?: string) {
  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);

  const [productsClient] = useClientsStore((store) => [store.productsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!id) {
          return;
        }

        const { data } = await productsClient.getProduct({
          productId: id,
          returnRelatedFeatures: true,
          returnRelatedAddons: true,
        });

        setProduct(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productsClient, id]);

  return { product, setProduct, isLoading };
}
