import type { FC } from "react";
import { useState } from "react";

import { Form, Modal } from "antd";
import Text from "antd/lib/typography/Text";

import type { SceneTemplateCustomPreviewsApiCreateSceneTemplateCustomPreviewWorkflowRequest } from "@omi-lab/atlas-typescript";

import { OrganizationSearch } from "./OrganizationSearch";
import { SceneTemplateSearchMultiple } from "./SceneTemplateSearchMultiple";

interface Props {
  isVisible: boolean;
  close: () => void;
  createSceneTemplateCustomPreview: (
    body: SceneTemplateCustomPreviewsApiCreateSceneTemplateCustomPreviewWorkflowRequest,
  ) => Promise<void>;
}

export const TemplateCustomPreviewConfirmWorkflowCreationModal: FC<Props> = (
  props,
) => {
  const [isCreating, setIsCreating] = useState(false);

  const [organizationId, setOrganizationId] = useState<string>();
  const [sceneTemplateIds, setSceneTemplateIds] = useState<string[]>([]);

  const createSceneTemplateCustomPreview = async () => {
    try {
      setIsCreating(true);

      await props.createSceneTemplateCustomPreview({
        organizationId,
        sceneTemplateIds,
      });

      props.close();
    } catch {
      props.close();
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Modal
      okText="Submit"
      open={props.isVisible}
      okButtonProps={{
        loading: isCreating,
        disabled: !organizationId || sceneTemplateIds.length === 0,
      }}
      onOk={createSceneTemplateCustomPreview}
      onCancel={props.close}
      cancelButtonProps={{
        loading: isCreating,
      }}
      style={{ width: "100%" }}
    >
      <Form style={{ width: "100%" }}>
        <Form.Item label="Organization" name="organization">
          <OrganizationSearch
            onChange={(id) => setOrganizationId(id)}
            onClear={() => setOrganizationId(undefined)}
          />
        </Form.Item>
        <Form.Item label="Templates" name="templates">
          <SceneTemplateSearchMultiple
            onDeselect={(id) =>
              setSceneTemplateIds(
                sceneTemplateIds.filter(
                  (sceneTemplateId) => sceneTemplateId !== id,
                ),
              )
            }
            onSelect={(id) => setSceneTemplateIds([...sceneTemplateIds, id])}
            onClear={() => setSceneTemplateIds([])}
          />
        </Form.Item>
        <Text disabled>
          Note that previous previews of the templates will be deleted for this
          organization.
        </Text>
      </Form>
    </Modal>
  );
};
