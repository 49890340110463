import { useEffect, useState } from "react";

import type { Camera } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetCamera(id: string) {
  const [camera, setcamera] = useState<Camera | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [camerasClient] = useClientsStore((store) => [store.camerasClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const camera = (
          await camerasClient.getCamera({
            cameraId: id,
          })
        ).data;

        setcamera(camera);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, camerasClient]);

  return { camera, isLoading };
}
