import type { FC } from "react";
import { useState } from "react";

import {
  Button,
  Col,
  List,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
} from "antd";

import type { SystemAdministrator } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { UpdateSystemAdministratorModal } from "../components/UpdateSystemAdministrator";
import { useListSystemAdministrators } from "../hooks/useListSystemAdministrators";

export const SystemAdministrators: FC = () => {
  const { administrators, setAdministrators } = useListSystemAdministrators();
  const [administrator, setAdministrator] = useState<
    SystemAdministrator | undefined
  >();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { systemAdministratorsClient } = useClientsStore();

  const updateSystemAdministrator = async (update: SystemAdministrator) => {
    try {
      const updated = await systemAdministratorsClient
        .updateSystemAdministrator({
          accountId: update.accountId,
          body: {
            permissions: update.permissions,
          },
          returnRelatedAccount: true,
        })
        .then((res) => res.data);

      setAdministrators(
        administrators.map((administrator) =>
          administrator.accountId === update.accountId
            ? updated
            : administrator,
        ),
      );

      notification.success({
        message: "The account was succesfully updated",
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setAdministrator(undefined);
      setIsModalVisible(false);
    }
  };

  const deleteSystemAdministrator = async (update: SystemAdministrator) => {
    try {
      await systemAdministratorsClient.deleteSystemAdministrator({
        accountId: update.accountId,
      });

      setAdministrators(
        administrators.filter(
          (administrator) => administrator.accountId !== update.accountId,
        ),
      );
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setAdministrator(undefined);
      setIsModalVisible(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <List grid={{ gutter: 16 }}>
        <List.Item>
          <Table
            columns={[
              {
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                width: "10%",
              },
              {
                title: "First Name",
                key: "firstName",
                render: (_, administrator) => (
                  <p>{administrator.account?.firstName}</p>
                ),
                width: "10%",
              },
              {
                title: "Last Name",
                key: "lastName",
                render: (_, administrator) => (
                  <p>{administrator.account?.lastName}</p>
                ),
                width: "10%",
              },
              {
                title: "Permissions",
                key: "permissions",
                render: (_, administrator) => (
                  <p>
                    {administrator.permissions.map((permission) => (
                      <Tag key={permission}>{permission}</Tag>
                    ))}
                  </p>
                ),
                width: "60%",
              },
              {
                title: "Actions",
                key: "actions",
                render: (_, administrator) => (
                  <Row>
                    <Space direction="horizontal">
                      <Button
                        onClick={() => {
                          setAdministrator(administrator);
                          setIsModalVisible(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Popconfirm
                        title="Are you sure to delete this scene?"
                        onConfirm={() =>
                          deleteSystemAdministrator(administrator)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" danger>
                          Delete
                        </Button>
                      </Popconfirm>
                    </Space>
                  </Row>
                ),
                width: "20%",
              },
            ]}
            dataSource={administrators}
            pagination={false}
          />
        </List.Item>
      </List>
      {isModalVisible && administrator && (
        <UpdateSystemAdministratorModal
          isOpen={isModalVisible}
          onOk={updateSystemAdministrator}
          onCancel={() => {
            setAdministrator(undefined);
            setIsModalVisible(false);
          }}
          administrator={administrator}
        />
      )}
    </Col>
  );
};
