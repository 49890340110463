import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  ArrowRightOutlined,
  CarryOutOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Row, Space, Tree } from "antd";
import type { Key } from "antd/lib/table/interface";
import type { TreeProps } from "antd/lib/tree";
import type { DataNode } from "rc-tree/lib/interface";

import type { CameraCollection } from "@omi-lab/frontend-core";

import { showErrorNotification } from "src/utils/error";

import { AppendModal } from "../components/AppendModal";
import type {
  Collection,
  CreateCollectionBody,
  UpdateCollectionBody,
} from "../types";
import { append, find, removePaths } from "../utils/tree";

interface Props {
  title: string;
  subTitle: string;
  listCollections: (
    page: number,
    pageSize: number,
  ) => Promise<Collection[] | CameraCollection[]>;
  createCollection: (
    element: CreateCollectionBody,
  ) => Promise<Collection | CameraCollection>;
  updateCollection: (
    path: string,
    element: UpdateCollectionBody,
  ) => Promise<Collection | CameraCollection>;
  deleteCollection: (path: string) => Promise<void>;
  onClickItem?: (collection: Collection) => void;
}

export const CollectionTree: FC<Props> = (props) => {
  const [collections, setCollections] = useState<
    Collection[] | CameraCollection[]
  >([]);

  const [checkedCollectionPaths, setCheckedCollectionPaths] = useState<
    string[]
  >([]);
  const [isAppending, setIsAppending] = useState(false);
  const [parentPath, setParentPath] = useState<string | undefined>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<
    Collection | undefined
  >();

  const { listCollections } = props;

  useEffect(() => {
    (async () => {
      try {
        setCollections(await listCollections(1, 200));
      } catch (error: unknown) {
        showErrorNotification(error);
      }
    })();
  }, [listCollections, setCollections]);

  const createCollection = async (collection: CreateCollectionBody) => {
    try {
      const newCollection = await props.createCollection(collection);

      setCollections(
        append(
          collections,
          collection.path!.split(".").slice(0, -1).join("."),
          newCollection,
        ),
      );
      setParentPath(undefined);
      setSelectedCollection(undefined);
      setIsAppending(false);
    } catch (error: unknown) {
      showErrorNotification(error);
    }
  };

  const updateCollection = useCallback(
    async (path: string, collection: UpdateCollectionBody) => {
      try {
        const c = await props.updateCollection(path, collection);

        setCollections((collections) => removePaths(collections, [path]));
        setCollections((collections) =>
          append(collections, path.split(".").slice(0, -1).join("."), c),
        );
        setParentPath(undefined);
        setSelectedCollection(undefined);
        setCheckedCollectionPaths([]);
        setIsAppending(false);
      } catch (error: unknown) {
        showErrorNotification(error);
      }
    },
    [props, setCollections],
  );

  const deleteCheckedCollections = async () => {
    try {
      setIsDeleting(true);

      await Promise.all(
        checkedCollectionPaths.map((path) => props.deleteCollection(path)),
      );

      setCollections(removePaths(collections, checkedCollectionPaths));
      setCheckedCollectionPaths([]);
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const buildNode = useMemo(
    () =>
      (collection: Collection): DataNode => {
        return {
          title: (
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid #e6e6e6",
                width: "550px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {collection.name} - {collection.path} - ({collection.visibility})
              <span style={{ display: "flex", gap: 20 }}>
                {collection.isHighlighted ? (
                  <StarFilled
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      await updateCollection(collection.path, {
                        ...collection,
                        isHighlighted: false,
                      });
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                ) : (
                  <StarOutlined
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      await updateCollection(collection.path, {
                        ...collection,
                        isHighlighted: true,
                      });
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                )}
                {collection.visibility === "public" ? (
                  <EyeOutlined
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      await updateCollection(collection.path, {
                        ...collection,
                        visibility: "hidden",
                      });
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      await updateCollection(collection.path, {
                        ...collection,
                        visibility: "public",
                      });
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                )}
                {props.onClickItem ? (
                  <ArrowRightOutlined
                    onClick={(e) => {
                      if (!props.onClickItem) return;
                      e.stopPropagation();
                      e.preventDefault();
                      props.onClickItem(collection);
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                ) : null}
              </span>
            </div>
          ),
          key: collection.path,
          icon: (
            <CarryOutOutlined
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          ),
          children: (collection.collections || []).map(buildNode),
          selectable: true,
        } as DataNode;
      },
    [props, updateCollection],
  );

  const onDrop: TreeProps["onDrop"] = async (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (!data[i]) continue;
        if (data[i]?.key === key) {
          return callback(data[i]!, i, data);
        }
        const children = data[i]?.children;
        if (children) {
          loop(children, key, callback);
        }
      }
    };
    const data = [...collections.map(buildNode)];

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((info.node as any).props.children || []).length > 0 && // Has children
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }

    await Promise.all(
      data.map((e, i) => {
        const col = collections.find((col) => col.path === e.key.toString());
        if (col) {
          updateCollection(col.path, {
            ...col,
            weight: i,
          });
        }
      }),
    );
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Space size="middle" direction="vertical" style={{ width: "100%" }}>
        <PageHeader
          className="site-page-header"
          title={props.title}
          subTitle={props.subTitle}
          style={{ border: "1px solid rgb(235, 237, 240)" }}
          ghost={false}
        ></PageHeader>
        <Tree
          draggable
          onDrop={onDrop}
          checkable
          checkStrictly
          checkedKeys={checkedCollectionPaths}
          showLine={false}
          showIcon={false}
          defaultExpandAll
          style={{ padding: "15px" }}
          treeData={[
            {
              title: "/",
              key: "/",
              children: collections
                .sort((a, b) => (a.weight || 0) - (b.weight || 0))
                .map(buildNode),
            },
          ]}
          onCheck={(checked) => {
            setCheckedCollectionPaths(
              (checked as { checked: Key[]; halfChecked: Key[] })
                .checked as string[],
            );
          }}
          onSelect={(_, { node }) => {
            setParentPath((node.key as string).replace("/", ""));
            setIsAppending(true);
          }}
        />
        {checkedCollectionPaths.length > 0 ? (
          <Row>
            <Space direction="horizontal">
              <Button
                type="primary"
                onClick={() => {
                  if (!checkedCollectionPaths[0]) return;
                  setSelectedCollection(
                    find(collections, checkedCollectionPaths[0]),
                  );
                  setIsAppending(true);
                }}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                loading={isDeleting}
                onClick={deleteCheckedCollections}
              >
                Delete
              </Button>
            </Space>
          </Row>
        ) : null}
      </Space>
      {isAppending && (
        <AppendModal
          parentPath={parentPath}
          isOpen
          onCancel={() => setIsAppending(false)}
          createCollection={createCollection}
          updateCollection={updateCollection}
          collection={selectedCollection}
        />
      )}
    </Col>
  );
};
