import { useEffect, useState } from "react";

import type {
  FilesApiGetFileRequest,
  ModelFile,
} from "@omi-lab/fedex-typescript";

import { useClientsStore } from "src/store/clients";

export const useFile = (
  fileId?: string | null,
  options?: Omit<FilesApiGetFileRequest, "fileId">,
) => {
  const client = useClientsStore((state) => state.filesClient);

  const [file, setFile] = useState<ModelFile>();
  const [isLoading, setIsLoading] = useState(false);

  const stringifiedOptions = JSON.stringify(options);

  useEffect(() => {
    const getFile = async () => {
      setIsLoading(true);

      if (!fileId) {
        setIsLoading(false);
        return null;
      }

      const { data } = await client.getFile({
        fileId,
        useCDN: true,
        returnRelatedPresignedURL: true,
        ...options,
      });

      setFile(data);
    };

    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, fileId, stringifiedOptions]);

  return { isLoading, file };
};
