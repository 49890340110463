import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  Button,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import Compressor from "compressorjs";

import type {
  LightSet,
  LightSetCollection,
  LightSetOrderBy,
  LightSetTag,
} from "@omi-lab/atlas-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";

import { extractErrorMessageFromError } from "src/utils/error";

import { Dropzone } from "../../../components/Dropzone";
import { useClientsStore } from "../../../store/clients";

type Props = {
  filter?: string;
  collectionFilters?: string[];
  collections: LightSetCollection[];
  tags: LightSetTag[];
  orderBy?: LightSetOrderBy;
};

export const LightSetList: FC<Props> = (props) => {
  const [lightSets, setLightSets] = useState<LightSet[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const lightSetsClient = useClientsStore((state) => state.lightSetsClient);

  useEffect(() => {
    const getLightSets = async () =>
      lightSetsClient
        .listLightSets({
          returnRelatedTags: true,
          returnRelatedCollections: true,
          nameIncludes: props.filter,
          collectionPaths: props.collectionFilters,
          orderBy: props.orderBy,
          page,
          pageSize,
        })
        .then((response) => {
          if (response.data.length >= pageSize) {
            setPageCount(page + 1);
          }
          setLightSets(response.data);
        });

    getLightSets();
  }, [
    lightSetsClient,
    setLightSets,
    page,
    pageSize,
    props.filter,
    props.collectionFilters,
    props.orderBy,
  ]);

  const updateLightSet = async (lightSet: LightSet) => {
    try {
      const update = (
        await lightSetsClient.updateLightSet({
          lightSetId: lightSet.id,
          // @ts-expect-error lightSet
          body: {
            ...lightSet,
            collections: lightSet.collections,
            name: lightSet.name,
            tags: (lightSet.tags || []).map((tag) => tag.name),
            thumbnailFileId: lightSet.thumbnailFileId,
          },
          returnRelatedCollections: true,
          returnRelatedTags: true,
        })
      ).data;

      setLightSets((lightSets) =>
        lightSets.map((lightSet) =>
          lightSet.id === update.id ? update : lightSet,
        ),
      );

      Modal.success({
        title: "Success",
        content: "The light set was successfully updated",
      });
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while deleting the light set",
        content: extractErrorMessageFromError(error),
      });
    }
  };

  const deleteLightSet = async (id: string) => {
    try {
      await lightSetsClient.deleteLightSet({ lightSetId: id });

      setLightSets(lightSets.filter((lightSet) => lightSet.id !== id));

      Modal.success({
        title: "Success",
        content: "The light set was successfully deleted",
      });
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while deleting the light set",
        content: extractErrorMessageFromError(error),
      });
    }
  };

  const addCollection = (lightSet: LightSet, collectionPath: string) => {
    const collection = props.collections.find(
      (collection) => collection.path === collectionPath,
    );

    if (collection) {
      setLightSets((lightSets) =>
        lightSets.map((c) =>
          c.id === lightSet.id
            ? {
                ...c,
                collections: [
                  ...(c.collections || []).filter(
                    (collection) => collection.path !== collectionPath,
                  ),
                  collection,
                ],
              }
            : c,
        ),
      );
    }
  };

  const removeCollection = (lightSet: LightSet, collectionPath: string) => {
    const collection = props.collections.find(
      (collection) => collection.path === collectionPath,
    );

    if (collection) {
      setLightSets((lightSets) =>
        lightSets.map((c) =>
          c.id === lightSet.id
            ? {
                ...c,
                collections: (c.collections || []).filter(
                  (collection) => collection.path !== collectionPath,
                ),
              }
            : c,
        ),
      );
    }
  };

  const addTag = (lightSet: LightSet, tagName: string) => {
    setLightSets((lightSets) =>
      lightSets.map((c) =>
        c.id === lightSet.id
          ? {
              ...c,
              tags: [
                ...(c.tags || []).filter((tag) => tag.name !== tagName),
                { name: tagName, createdAt: new Date(), updatedAt: new Date() },
              ],
            }
          : c,
      ),
    );
  };

  const removeTag = (lightSet: LightSet, tagName: string) => {
    setLightSets((lightSets) =>
      lightSets.map((c) =>
        c.id === lightSet.id
          ? {
              ...c,
              tags: (c.tags || []).filter((tag) => tag.name !== tagName),
            }
          : c,
      ),
    );
  };

  const setName = (lightSet: LightSet, name: string) => {
    setLightSets((lightSets) =>
      lightSets.map((c) =>
        c.id === lightSet.id
          ? {
              ...c,
              name,
            }
          : c,
      ),
    );
  };

  return (
    <Table
      dataSource={lightSets}
      pagination={{
        position: ["bottomRight"],
        pageSize,
        onChange: setPage,
        current: page,
        total: pageCount * pageSize,
        onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
        showSizeChanger: true,
      }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: "15%",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          width: "15%",
          render: (name: string, record: LightSet) => (
            <Input
              value={name}
              onChange={(e) => setName(record, e.target.value)}
            />
          ),
        },
        {
          title: "Tags",
          dataIndex: "tags",
          key: "tags",
          width: "25%",
          render: (tags: LightSetTag[], record: LightSet) => (
            <Select
              mode="tags"
              placeholder="Select tags"
              style={{ width: "100%" }}
              showArrow
              maxTagCount={1}
              value={tags.map((tag) => tag.name)}
              onSelect={(key) => addTag(record, key)}
              onDeselect={(key) => removeTag(record, key)}
            >
              {props.tags.map((tag) => (
                <Select.Option key={tag.name} value={tag.name}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Collections",
          dataIndex: "collections",
          key: "collections",
          width: "25%",
          render: (collections: LightSetCollection[], record: LightSet) => (
            <Select
              mode="multiple"
              placeholder="Select collections"
              style={{ width: "100%" }}
              showArrow
              maxTagCount={1}
              value={collections.map((collection) => collection.path)}
              onSelect={(key) => addCollection(record, key)}
              onDeselect={(key) => removeCollection(record, key)}
            >
              {props.collections.map((collection) => (
                <Select.Option key={collection.path} value={collection.path}>
                  {collection.name}
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "update",
          width: "20%",
          render: (_, record: LightSet) => (
            <Space direction="horizontal" size="small">
              <Popconfirm
                title="Are you sure to update this lightSet?"
                onConfirm={() => updateLightSet(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Update</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure to delete this lightSet?"
                onConfirm={() => deleteLightSet(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="dashed" danger>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
        {
          title: "Thumbnail",
          dataIndex: "",
          key: "upload",
          width: "20%",
          render: (_, record: LightSet) => (
            <LightSetUploader
              key={record.id}
              lightSet={record}
              updateLightSet={updateLightSet}
            />
          ),
        },
      ]}
    />
  );
};

type LightSetUploaderProps = {
  lightSet: LightSet;
  updateLightSet: (lightSet: LightSet) => void;
};

const LightSetUploader = ({
  lightSet,
  updateLightSet,
}: LightSetUploaderProps) => {
  const filesClient = useClientsStore((store) => store.filesClient);
  const [thumbnail, setThumbnail] = useState<ModelFile>();
  const [uploadFile, setUploadFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!lightSet.thumbnailFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: lightSet.thumbnailFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, lightSet.thumbnailFileId]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {thumbnail?.url ? (
        <img alt="thumb" src={thumbnail?.url} style={{ maxWidth: "200px" }} />
      ) : null}

      <Dropzone
        onDrop={async (files) => {
          if (!files[0]) return;
          new Compressor(files[0], {
            maxWidth: 562,
            success: async (compressed: File) => {
              const file = await filesClient
                .uploadFile({
                  file: compressed,
                  name: `${lightSet.name} - Thumbnail`,
                })
                .then((response) => response.data);

              updateLightSet({ ...lightSet, thumbnailFileId: file.id });
              setUploadFile(compressed);
            },
            error: (error) => {
              notification.error({
                message: error.message,
              });
            },
          });
        }}
        files={uploadFile ? [uploadFile] : []}
        accept="image/*"
        maxFiles={1}
      />
    </div>
  );
};
