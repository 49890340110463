import type { FC } from "react";
import { useState } from "react";

import { Button, Input, notification, Popconfirm, Row, Space } from "antd";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

interface Props {
  quoteId: string;
}

export const SendLink: FC<Props> = (props) => {
  const [email, setEmail] = useState<string>();
  const [isSendingLink, setIsSendingLink] = useState(false);

  const [quotesClient] = useClientsStore((store) => [store.quotesClient]);

  const sendLink = async () => {
    try {
      setIsSendingLink(true);

      if (!email) {
        return;
      }

      await quotesClient
        .sendQuoteLink({
          quoteId: props.quoteId,
          body: {
            email,
          },
        })
        .then((response) => response.data);

      setEmail(undefined);

      notification.success({
        message: "Successfully sent the link.",
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsSendingLink(false);
    }
  };

  return (
    <Row>
      <Space direction="horizontal" style={{ width: "100%" }}>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Popconfirm
          title="Are you sure you want to email a link for this quote ?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => sendLink()}
          okButtonProps={{ loading: isSendingLink }}
        >
          <Button type="primary" disabled={isSendingLink}>
            Send
          </Button>
        </Popconfirm>
      </Space>
    </Row>
  );
};
