import { useEffect, useState } from "react";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetProductCount(params: { organizationId?: string }) {
  const [productCount, setProductCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [productsClient] = useClientsStore((store) => [
    store.atlasProductsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await productsClient.countProducts({
          organizationId: params.organizationId,
        });

        setProductCount(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productsClient, params.organizationId]);

  return { productCount, setProductCount, isLoading };
}
