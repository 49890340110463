import type { FC } from "react";
import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";

import type {
  Camera,
  CameraCollection,
  CameraOrderBy,
  CameraTag,
} from "@omi-lab/atlas-typescript";

import { Dropzone } from "src/components/Dropzone";
import { useFile } from "src/hooks/useFile";
import { extractErrorMessageFromError } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

type CameraImgProps = {
  thumbnailId?: string;
  cameraId: string;
  isStill: boolean;
};
const CameraImg = ({ thumbnailId, cameraId, isStill }: CameraImgProps) => {
  const [uploadFile, setUploadFile] = useState<File>();
  const { filesClient, camerasClient } = useClientsStore((state) => state);
  const { file } = useFile(thumbnailId, {
    returnRelatedCategories: true,
  });

  const url = file?.url ?? undefined;

  const updateCamera = async (thumbnailFileId: string) => {
    await camerasClient.updateCamera({
      body: {
        thumbnailFileId,
      },
      cameraId,
    });
  };

  const uploadThumbnailFile = async (file: File) => {
    const { data } = await filesClient.uploadFile({
      file,
      name: file.name,
    });

    await updateCamera(data.id);
  };

  const handleOnDrop = async (files: File[]) => {
    if (!files[0]) return;
    setUploadFile(files[0]);
    uploadThumbnailFile(files[0]);
  };

  if (!url) {
    return (
      <Dropzone
        onDrop={handleOnDrop}
        files={uploadFile ? [uploadFile] : []}
        accept="image/*,video/*"
        maxFiles={1}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {isStill ? (
        <Image height={80} alt="thumbnail" src={url} />
      ) : (
        <video height={80} src={url} autoPlay loop />
      )}
      <Dropzone
        onDrop={handleOnDrop}
        files={uploadFile ? [uploadFile] : []}
        accept="image/*"
        maxFiles={1}
      />
    </div>
  );
};

type Props = {
  filter?: string;
  collectionFilters?: string[];
  collections: CameraCollection[];
  tags: CameraTag[];
  orderBy?: CameraOrderBy;
};

export const CameraList: FC<Props> = (props) => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const camerasClient = useClientsStore((state) => state.camerasClient);

  useEffect(() => {
    const getCameras = async () =>
      camerasClient
        .listCameras({
          returnRelatedTags: true,
          returnRelatedCollections: true,
          returnRelatedPaths: true,
          nameIncludes: props.filter,
          collectionPaths: props.collectionFilters,
          orderBy: props.orderBy,
          page,
          pageSize,
        })
        .then((response) => {
          if (response.data.length >= pageSize) {
            setPageCount(page + 1);
          }
          setCameras(response.data);
        });

    getCameras();
  }, [
    camerasClient,
    setCameras,
    page,
    pageSize,
    props.filter,
    props.collectionFilters,
    props.orderBy,
  ]);

  const updateCamera = async (camera: Camera) => {
    try {
      const update = (
        await camerasClient.updateCamera({
          cameraId: camera.id,
          body: {
            collections: camera.collections,
            isStill: camera.isStill,
            name: camera.name,
            customProperties: camera.customProperties,
            tags: (camera.tags || []).map((tag) => tag.name),
          },
          returnRelatedPaths: true,
          returnRelatedCollections: true,
          returnRelatedTags: true,
        })
      ).data;

      setCameras((cameras) =>
        cameras.map((camera) => (camera.id === update.id ? update : camera)),
      );

      Modal.success({
        title: "Success",
        content: "The camera was successfully updated",
      });
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while deleting the camera",
        content: extractErrorMessageFromError(error),
      });
    }
  };

  const deleteCamera = async (id: string) => {
    try {
      await camerasClient.deleteCamera({ cameraId: id });

      setCameras(cameras.filter((camera) => camera.id !== id));

      Modal.success({
        title: "Success",
        content: "The camera was successfully deleted",
      });
    } catch (error: unknown) {
      Modal.error({
        title: "An error occured while deleting the camera",
        content: extractErrorMessageFromError(error),
      });
    }
  };

  const addCollection = (camera: Camera, collectionPath: string) => {
    const collection = props.collections.find(
      (collection) => collection.path === collectionPath,
    );

    if (collection) {
      setCameras((cameras) =>
        cameras.map((c) =>
          c.id === camera.id
            ? {
                ...c,
                collections: [
                  ...(c.collections || []).filter(
                    (collection) => collection.path !== collectionPath,
                  ),
                  collection,
                ],
              }
            : c,
        ),
      );
    }
  };

  const removeCollection = (camera: Camera, collectionPath: string) => {
    const collection = props.collections.find(
      (collection) => collection.path === collectionPath,
    );

    if (collection) {
      setCameras((cameras) =>
        cameras.map((c) =>
          c.id === camera.id
            ? {
                ...c,
                collections: (c.collections || []).filter(
                  (collection) => collection.path !== collectionPath,
                ),
              }
            : c,
        ),
      );
    }
  };

  const addTag = (camera: Camera, tagName: string) => {
    setCameras((cameras) =>
      cameras.map((c) =>
        c.id === camera.id
          ? {
              ...c,
              tags: [
                ...(c.tags || []).filter((tag) => tag.name !== tagName),
                { name: tagName, createdAt: new Date(), updatedAt: new Date() },
              ],
            }
          : c,
      ),
    );
  };

  const removeTag = (camera: Camera, tagName: string) => {
    setCameras((cameras) =>
      cameras.map((c) =>
        c.id === camera.id
          ? {
              ...c,
              tags: (c.tags || []).filter((tag) => tag.name !== tagName),
            }
          : c,
      ),
    );
  };

  const setName = (camera: Camera, name: string) => {
    setCameras((cameras) =>
      cameras.map((c) =>
        c.id === camera.id
          ? {
              ...c,
              name,
            }
          : c,
      ),
    );
  };

  return (
    <Table
      dataSource={cameras}
      pagination={{
        position: ["bottomRight"],
        pageSize,
        onChange: setPage,
        current: page,
        total: pageCount * pageSize,
        onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
        showSizeChanger: true,
      }}
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: "10%",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          width: "10%",
          render: (name: string, record: Camera) => (
            <Input
              value={name}
              onChange={(e) => setName(record, e.target.value)}
            />
          ),
        },
        {
          title: "Is Still",
          dataIndex: "isStill",
          key: "isStill",
          width: "10%",
          render: (isStill: boolean, record: Camera) => (
            <Checkbox
              onChange={(e) =>
                setCameras((cameras) =>
                  cameras.map((camera) =>
                    camera.id === record.id
                      ? { ...camera, isStill: e.target.checked }
                      : camera,
                  ),
                )
              }
              checked={isStill}
            />
          ),
        },
        {
          title: "Tags",
          dataIndex: "tags",
          key: "tags",
          width: "25%",
          render: (tags: CameraTag[], record: Camera) => (
            <Select
              mode="tags"
              placeholder="Select tags"
              style={{ width: "100%" }}
              showArrow
              maxTagCount={1}
              value={tags.map((tag) => tag.name)}
              onSelect={(key: string) => addTag(record, key)}
              onDeselect={(key: string) => removeTag(record, key)}
            >
              {props.tags.map((tag) => (
                <Select.Option key={tag.name} value={tag.name}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Collections",
          dataIndex: "collections",
          key: "collections",
          width: "25%",
          render: (collections: CameraCollection[], record: Camera) => (
            <Select
              mode="multiple"
              placeholder="Select collections"
              style={{ width: "100%" }}
              showArrow
              maxTagCount={1}
              value={collections.map((collection) => collection.path)}
              onSelect={(key: string) => addCollection(record, key)}
              onDeselect={(key: string) => removeCollection(record, key)}
            >
              {props.collections.map((collection) => (
                <Select.Option key={collection.path} value={collection.path}>
                  {collection.name}
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          title: "Thumbnail",
          dataIndex: "thumbnailFileId",
          key: "thumbnailFileId",
          width: "30%",
          render: (thumbnailFileId: string, record: Camera) => (
            <CameraImg
              thumbnailId={thumbnailFileId}
              cameraId={record.id}
              isStill={record.isStill}
            />
          ),
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "update",
          width: "20%",
          render: (_, record: Camera) => (
            <Space direction="horizontal" size="small">
              <Popconfirm
                title="Are you sure to update this camera?"
                onConfirm={() => updateCamera(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Update</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure to delete this camera?"
                onConfirm={() => deleteCamera(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="dashed" danger>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ]}
    />
  );
};
