import { useEffect, useState } from "react";

import type { ModelObject, ObjectVisibility } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useSearchObjects(
  id?: string,
  visibilityIn?: ObjectVisibility[],
) {
  const [search, setSearch] = useState("");
  const [objects, setObjects] = useState<ModelObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [objetsClient] = useClientsStore((store) => [store.objectsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await objetsClient
          .listObjects({
            nameIncludes: search,
            organizationId: id,
            page: 1,
            pageSize: 30,
            visibilityIn: visibilityIn || ["public"],
          })
          .then((response) => setObjects(response.data));
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [objetsClient, id, visibilityIn, search]);

  return { objects, search, setSearch, isLoading };
}
