import type { NotificationArgsProps } from "antd";
import { message, notification } from "antd";
import { AxiosError } from "axios";

export function extractErrorMessageFromError(error: unknown) {
  if (error instanceof AxiosError) {
    return error?.response?.data?.error || error?.message;
  }
  if (error instanceof Error) {
    return error?.message;
  }
  return `${error}`;
}

export function showErrorMessage(error: unknown) {
  return message.error(extractErrorMessageFromError(error));
}

export function showErrorNotification(
  error: unknown,
  config?: Partial<NotificationArgsProps>,
) {
  return notification.error({
    message: extractErrorMessageFromError(error),
    ...config,
  });
}
