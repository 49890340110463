import type { FC } from "react";

import { Form, Input, Modal } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import type { CreateProductVerticalBody } from "@omi-lab/ceos-typescript";

interface Props {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  createProductVertical: (body: CreateProductVerticalBody) => Promise<void>;
}

export const AddProductVerticalModal: FC<Props> = ({
  isVisible,
  setIsVisible,
  createProductVertical,
}) => {
  const { values, handleChange, handleSubmit, isValid } = useFormik({
    initialValues: {
      name: "",
      slug: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      slug: Yup.string().required(),
    }).required(),
    onSubmit: async (values, { resetForm }) => {
      await createProductVertical(values);
      resetForm();
    },
  });

  return (
    <Modal
      title="Add a vertical"
      open={isVisible}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: !isValid }}
      onCancel={() => setIsVisible(false)}
    >
      <Form.Item>
        <Input
          placeholder="Name"
          name="name"
          onChange={handleChange}
          value={values.name}
        />
      </Form.Item>
      <Form.Item>
        <Input
          placeholder="Display name"
          name="slug"
          onChange={handleChange}
          value={values.slug}
        />
      </Form.Item>
    </Modal>
  );
};
