export enum STEPS {
  FILL_DETAILS = 0,
  CREATE_ADDONS = 1,
  SUMMARY = 2,
}

export const steps = [
  {
    key: STEPS.FILL_DETAILS,
    title: "Fill in the details",
  },
  {
    key: STEPS.CREATE_ADDONS,
    title: "Create the addons",
  },
  {
    key: STEPS.SUMMARY,
    title: "Summary",
  },
];
