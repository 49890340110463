import { useEffect, useState } from "react";

import { PageHeader } from "@ant-design/pro-layout";
import { Col, Space, Tree } from "antd";
import type { TreeProps } from "antd/lib/tree";
import type { DataNode } from "rc-tree/lib/interface";

type CollectionTreeV2Props = {
  title: string;
  subtitle: string;
  defaultDataTree: DataNode[];
  onUpdateDataTree: (dataTree: DataNode[]) => void;
};

export const CollectionTreeV2 = ({
  title,
  subtitle,
  defaultDataTree,
  onUpdateDataTree,
}: CollectionTreeV2Props) => {
  const [dataTree, setDataTree] = useState(defaultDataTree);
  const [expandedKeys] = useState([]);
  useEffect(() => {
    setDataTree(defaultDataTree);
  }, [defaultDataTree]);

  const onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void,
    ) => {
      for (const [index, item] of data.entries()) {
        if (item?.key === key) {
          return callback(item!, index, data);
        }
        const children = item?.children;
        if (children) {
          loop(children, key, callback);
        }
      }
    };

    const data = [...dataTree];

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((info.node as any).props.children || []).length > 0 && // Has children
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    onUpdateDataTree(data);
    setDataTree(data);
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Space size="middle" direction="vertical" style={{ width: "100%" }}>
        <PageHeader
          className="site-page-header"
          title={title}
          subTitle={subtitle}
          style={{ border: "1px solid rgb(235, 237, 240)" }}
          ghost={false}
        />
        {dataTree.length === 0 ? <div>No data</div> : null}
        <Tree
          defaultExpandedKeys={expandedKeys}
          draggable
          blockNode
          onDrop={onDrop}
          treeData={dataTree}
        />
      </Space>
    </Col>
  );
};
