import type { FC } from "react";
import { useState } from "react";

import { Col, Form, Input, Modal, Radio } from "antd";
import { useFormik } from "formik";

import type { CreateCouponBody } from "@omi-lab/cresus-typescript";

import { ValidationScheme } from "./Coupons.schema";

interface Props {
  isOpen: boolean;
  close: () => void;
  createCoupon: (body: CreateCouponBody) => Promise<void>;
}

export const CreateCouponModal: FC<Props> = (props) => {
  const [isCouponTypePercentOff, setIsCouponTypePercentOff] =
    useState<boolean>(true);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
    } as CreateCouponBody,
    onSubmit: props.createCoupon,
    validateOnMount: false,
    validationSchema: ValidationScheme,
  });

  return (
    <Modal
      title="Create coupon"
      open={props.isOpen}
      onOk={() => handleSubmit()}
      onCancel={() => {
        resetForm();
        props.close();
      }}
    >
      <Col style={{ padding: "15px" }}>
        <Form initialValues={values}>
          <Form.Item
            label="Name"
            name="name"
            help={touched.name && errors.name}
            validateStatus={touched.name && errors.name ? "error" : "success"}
          >
            <Input
              defaultValue={values.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
          <Form.Item label="Type" name="type">
            <Radio.Group
              defaultValue={isCouponTypePercentOff ? "percent" : "amount"}
              value={isCouponTypePercentOff ? "percent" : "amount"}
              buttonStyle="solid"
              size="small"
              onChange={(e) =>
                e.target.value === "percent"
                  ? setIsCouponTypePercentOff(true)
                  : setIsCouponTypePercentOff(false)
              }
            >
              <Radio.Button value="percent">Percent</Radio.Button>
              <Radio.Button value="amount">Amount</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {isCouponTypePercentOff ? (
            <Form.Item
              label="Percent off"
              name="percentOff"
              help={touched.percentOff && errors.percentOff}
              validateStatus={
                touched.percentOff && errors.percentOff ? "error" : "success"
              }
            >
              <Input
                type="number"
                disabled={!!values.amountOff}
                defaultValue={values.percentOff!}
                value={values.percentOff!}
                onChange={handleChange}
                onBlur={handleBlur}
                addonAfter="%"
              />
            </Form.Item>
          ) : undefined}
          {!isCouponTypePercentOff ? (
            <Form.Item
              label="Amount off"
              name="amountOff"
              help={touched.amountOff && errors.amountOff}
              validateStatus={
                touched.amountOff && errors.amountOff ? "error" : "success"
              }
            >
              <Input
                type="number"
                disabled={!!values.percentOff}
                defaultValue={values.amountOff!}
                value={values.amountOff!}
                onChange={handleChange}
                onBlur={handleBlur}
                addonAfter="€"
              />
            </Form.Item>
          ) : undefined}
        </Form>
      </Col>
    </Modal>
  );
};
