import type { FC } from "react";

import { Col } from "antd";

import { Form } from "../components/Form";

export const ProductCreate: FC = (props) => {
  return (
    <Col style={{ padding: "15px" }}>
      <Form {...props} />
    </Col>
  );
};
