import { useEffect, useState } from "react";

import type { TextContent } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetTextContents(textContentId?: string) {
  const [textContent, setTextContent] = useState<TextContent>();
  const [isLoading, setIsLoading] = useState(false);

  const client = useClientsStore((store) => store.textContentsClient);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!textContentId) return;
        const { data } = await client.getTextContent({
          returnRelatedTranslations: true,
          textContentId,
        });

        setTextContent(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [client, textContentId]);

  return { textContent, setTextContent, isLoading };
}
