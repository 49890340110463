import type { FC } from "react";
import { useCallback } from "react";

import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";

import { Carousel, notification } from "antd";

import { Container } from "../components/Container";
import { Text } from "../components/Text";

interface Props {
  onDrop(files: File[]): void;
  files: File[];
  message?: string;
  onDropMessage?: string;
  accept?: string;
  maxSize?: number;
  maxFiles?: number;
  preview?: boolean;
  hideThumbnail?: boolean;
}

export const Dropzone: FC<Props> = ({
  onDrop,
  files,
  message,
  onDropMessage,
  accept,
  maxSize,
  maxFiles,
  hideThumbnail,
}) => {
  const callback = useCallback(
    (files: File[], rejected: FileRejection[]) => {
      if (rejected.length) {
        notification.error({
          message: "An error occured while processing files",
          description: (
            <ul>
              {rejected.map((error) => (
                <li key={error?.errors?.[0]?.message}>
                  {`${error.file.name}: ${error.errors
                    .map((error) => error.message)
                    .join(",")}`}
                </li>
              ))}
            </ul>
          ),
        });
        return;
      }

      onDrop(files);
    },
    [onDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: callback,
    accept: accept,
    maxSize: maxSize,
    maxFiles: maxFiles,
  });

  return (
    <>
      <Container {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text>{onDropMessage || "Drop the files here ..."}</Text>
        ) : (
          <Text>
            {message ||
              "Drag 'n' drop some files here, or click to select files"}
          </Text>
        )}
      </Container>
      {!hideThumbnail ? (
        <Carousel>
          {files.map((file) => (
            <img
              key={file.name}
              src={URL.createObjectURL(file)}
              alt={`preview-${file.name}`}
            />
          ))}
        </Carousel>
      ) : (
        files.map((file) => <p key={file.name}>{file.name}</p>)
      )}
    </>
  );
};
