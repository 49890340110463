import { useEffect, useState } from "react";

import type { TextContent } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export enum TextContentReference {
  ALL = "all",
  SCENE_TEMPLATE_COLLECTION_NAME = "scene_template_collection_name",
  SCENE_TEMPLATE_TAG_NAME = "scene_template_tag_name",
  SCENE_TEMPLATE_NAME = "scene_template_name",
  SCHEDULED_EVENT = "scheduled_event",
  ANIMATION = "animation",
  CAMERA_COLLECTION = "camera_collection",
  CAMERA = "camera",
  LIGHT_SET_COLLECTION = "light_set_collection",
  LIGHT_SET = "light_set",
  MATERIAL_COLLECTION = "material_collection",
  MATERIAL = "material",
  OBJECT_COLLECTION = "object_collection",
  OBJECT = "object",
  SKY_BOX_COLLECTION = "sky_box_collection",
  SKY_BOX = "sky_box",
  COMPOSITION_PRESET = "composition_preset",
  FORMAT_PRESET_SLUG = "format_preset_slug",
  RATIO_PRESET = "ratio_preset",
  RATIO_PRESET_GROUP = "ratio_preset_group",
}

export function useListTextContents(params: {
  page?: number;
  pageSize?: number;
  nameIncludes?: string;
  isReferencedBy?: TextContentReference;
}) {
  const [textContents, setTextContents] = useState<TextContent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const client = useClientsStore((store) => store.textContentsClient);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await client.listTextContents({
          returnRelatedTranslations: true,
          page: params.page,
          pageSize: params.pageSize,
          nameIncludes: params.nameIncludes,
          isReferencedBySceneTemplateCollectionName:
            params.isReferencedBy ===
            TextContentReference.SCENE_TEMPLATE_COLLECTION_NAME,
          isReferencedBySceneTemplateName:
            params.isReferencedBy === TextContentReference.SCENE_TEMPLATE_NAME,
          isReferencedBySceneTemplateTagName:
            params.isReferencedBy ===
            TextContentReference.SCENE_TEMPLATE_TAG_NAME,
          isReferencedByScheduledEvent:
            params.isReferencedBy === TextContentReference.SCHEDULED_EVENT,
          isReferencedByAnimation:
            params.isReferencedBy === TextContentReference.ANIMATION,
          isReferencedByCamera:
            params.isReferencedBy === TextContentReference.CAMERA,
          isReferencedByCameraCollection:
            params.isReferencedBy === TextContentReference.CAMERA_COLLECTION,
          isReferencedByLightSet:
            params.isReferencedBy === TextContentReference.LIGHT_SET,
          isReferencedByLightSetCollection:
            params.isReferencedBy === TextContentReference.LIGHT_SET_COLLECTION,
          isReferencedByMaterial:
            params.isReferencedBy === TextContentReference.MATERIAL,
          isReferencedByMaterialCollection:
            params.isReferencedBy === TextContentReference.MATERIAL_COLLECTION,
          isReferencedByObject:
            params.isReferencedBy === TextContentReference.OBJECT,
          isReferencedByObjectCollection:
            params.isReferencedBy === TextContentReference.OBJECT_COLLECTION,
          isReferencedBySkyBox:
            params.isReferencedBy === TextContentReference.SKY_BOX,
          isReferencedBySkyBoxCollection:
            params.isReferencedBy === TextContentReference.SKY_BOX_COLLECTION,
          isReferencedByCompositionPreset:
            params.isReferencedBy === TextContentReference.COMPOSITION_PRESET,
          isReferencedByFormatPresetSlug:
            params.isReferencedBy === TextContentReference.FORMAT_PRESET_SLUG,
          isReferencedByRatioPreset:
            params.isReferencedBy === TextContentReference.RATIO_PRESET,
          isReferencedByRatioPresetGroup:
            params.isReferencedBy === TextContentReference.RATIO_PRESET_GROUP,
        });

        setTextContents(data.data);

        setCount(data.count);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    client,
    params.page,
    params.pageSize,
    params.nameIncludes,
    params.isReferencedBy,
  ]);

  return { textContents, setTextContents, isLoading, count };
}
