import type { FC } from "react";

// import { useClientsStore } from "../../../store/clients";
// import { CollectionTree } from "components/CollectionTree";
// import {
//   CreateCollectionBody,
//   UpdateCollectionBody,
// } from "components/CollectionTree/types";
// import { ObjectCollection } from "@omi-lab/atlas-typescript";

export const ObjectCollections: FC = () => {
  // const [objectCollectionsClient, filesClient] = useClientsStore((store) => [
  //   store.objectCollectionsClient,
  //   store.filesClient,
  // ]);

  // const createCollection = async (
  //   collection: CreateCollectionBody
  // ): Promise<ObjectCollection> => {
  //   const file = await filesClient
  //     .uploadFile({
  //       file: collection.thumbnail!,
  //       name: collection.thumbnail!.name,
  //     })
  //     .then((response) => response.data);

  //   return await objectCollectionsClient
  //     .createObjectCollection({
  //       body: {
  //         name: collection.name!,
  //         path: collection.path!,
  //         thumbnailFileId: file.id,
  //       },
  //     })
  //     .then((response) => response.data);
  // };

  // const updateCollection = async (
  //   path: string,
  //   collection: UpdateCollectionBody
  // ): Promise<ObjectCollection> => {
  //   const file = await filesClient
  //     .uploadFile({
  //       file: collection.thumbnail!,
  //       name: collection.thumbnail!.name,
  //     })
  //     .then((response) => response.data);

  //   return await objectCollectionsClient
  //     .updateObjectCollection({
  //       objectCollectionPath: path,
  //       body: {
  //         name: collection.name!,
  //         thumbnailFileId: file.id,
  //       },
  //     })
  //     .then((response) => response.data);
  // };

  // const deleteCollection = async (path: string) => {
  //   await objectCollectionsClient
  //     .deleteObjectCollection({
  //       objectCollectionPath: path,
  //     })
  //     .then((response) => response.data);
  // };

  return (
    <div>ON HOLD</div>
    // <CollectionTree
    //   title="Object collections"
    //   subTitle="Click on a collection to create a new child"
    //   listCollections={(page, pageSize) =>
    //     objectCollectionsClient
    //       .listObjectCollections({
    //         page,
    //         pageSize,
    //       })
    //       .then((response) => response.data)
    //   }
    //   createCollection={createCollection}
    //   deleteCollection={deleteCollection}
    //   updateCollection={updateCollection}
    // />
  );
};
