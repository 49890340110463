import { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";

import type { Account } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useSearchAccountsByEmail(emailIncludes: string) {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const accountsClient = useClientsStore((store) => store.accountsApi);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedListAccounts = useCallback(
    debounce(async (emailIncludes: string) => {
      if (!emailIncludes) {
        setAccounts([]);
        return;
      }
      setIsLoading(true);
      try {
        const { data } = await accountsClient.listAccounts({
          emailIncludes,
        });
        setAccounts(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [accountsClient],
  );

  useEffect(() => {
    debouncedListAccounts(emailIncludes);
  }, [emailIncludes, debouncedListAccounts]);

  return { accounts, isLoading };
}
