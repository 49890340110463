import { useEffect, useState } from "react";

import type { Organization } from "@omi-lab/ceos-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useGetOrganizations(id?: string) {
  const [organization, setOrganization] = useState<Organization>();
  const [isLoading, setIsLoading] = useState(false);

  const [organizationsClient] = useClientsStore((store) => [
    store.organizationsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!id) {
          return;
        }

        const { data } = await organizationsClient.getOrganization({
          organizationId: id,
        });

        setOrganization(data);
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationsClient, id]);

  return { organization, setOrganization, isLoading };
}
