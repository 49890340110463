import type { FC } from "react";

import { Select } from "antd";

import { useSearchOrganizations } from "../hooks/useSearchOrganizations";

interface Props {
  onChange: (id: string) => void;
  onClear: () => void;
}

export const OrganizationSearch: FC<Props> = (props) => {
  const { organizations, setSearch } = useSearchOrganizations();

  return (
    <Select
      allowClear
      showSearch
      style={{ width: "100%" }}
      placeholder="Search for organizations"
      filterOption={false}
      defaultValue={undefined}
      onSearch={setSearch}
      onChange={props.onChange}
      onClear={props.onClear}
    >
      {organizations.map((organization) => (
        <Select.Option value={organization.id} key={organization.id}>
          {organization.name}
        </Select.Option>
      ))}
    </Select>
  );
};
