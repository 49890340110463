import type { FC } from "react";

import { Col, Typography } from "antd";

import { Addons } from "./Addons";
import { Details } from "./Details";

export const Summary: FC = () => {
  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Typography.Title level={4}>Details</Typography.Title>
      <Details disabled />
      <Typography.Title level={4}>Addons</Typography.Title>
      <Addons disabled />
    </Col>
  );
};
