import type { FC } from "react";
import { useEffect, useState } from "react";

import { Input, Modal, Select, Spin } from "antd";
import { useFormik } from "formik";

import type {
  SceneTemplate,
  SceneTemplateTag,
  SceneTemplateVisibility,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";
import { OrganizationSearch } from "../../Billing/components/OrganizationSearch";
import { useListTemplateTags } from "../hooks/useListTemplateTags";

interface Props {
  sceneTemplateId: string;
  visible: boolean;
  hide: () => void;
}

const ratios = [
  { name: "LandScape", value: "landscape" },
  { name: "Story", value: "story" },
  { name: "Square", value: "square" },
];

export const TemplateEditResourcesModal: FC<Props> = (props) => {
  const [sceneTemplate, setSceneTemplate] = useState<SceneTemplate>();
  const [isLoadingSceneTemplate, setIsLoadingSceneTemplate] = useState(false);

  const { templateTags, isLoading: templateTagsLoading } =
    useListTemplateTags();

  const sceneTemplatesClient = useClientsStore(
    (state) => state.sceneTemplatesClient,
  );

  const { values, setValues, submitForm } = useFormik({
    initialValues: {
      tags: [] as SceneTemplateTag[],
      visibility: sceneTemplate?.visibility || "public",
      name: sceneTemplate?.name,
      organizationId: sceneTemplate?.organizationId,
      preferredRatio: sceneTemplate?.preferredRatio || "landscape",
    },
    onSubmit: async (values) => {
      try {
        setIsLoadingSceneTemplate(true);

        if (!sceneTemplate) {
          return;
        }

        await sceneTemplatesClient.updateSceneTemplate({
          sceneTemplateId: sceneTemplate.id,
          body: {
            name: values.name || sceneTemplate.name,
            visibility:
              (values.visibility as SceneTemplateVisibility) ||
              sceneTemplate.visibility,
            organizationId:
              values.visibility === "private"
                ? values.organizationId || sceneTemplate.organizationId
                : undefined,
            // collections: sceneTemplate.collections,
            // description: sceneTemplate.description,
            tags: values.tags.map((tag) => tag.name),
            preferredRatio: values.preferredRatio,
          },
        });
      } catch {
        Modal.error({
          content: "An error occured while updating the template.",
        });
      } finally {
        setIsLoadingSceneTemplate(false);
        props.hide();
      }
    },
  });

  useEffect(() => {
    const getScene = async () => {
      try {
        setIsLoadingSceneTemplate(true);

        sceneTemplatesClient
          .getSceneTemplate({
            returnRelatedTags: true,
            returnRelatedCollections: true,
            sceneTemplateId: props.sceneTemplateId,
          })
          .then((response) => response.data)
          .then((scene) => {
            setSceneTemplate(scene);
            setValues((values) => ({
              ...values,
              visibility: scene.visibility,
              name: scene.name,
              organizationId: scene.organizationId,
              preferredRatio: scene.preferredRatio,
            }));
          });
      } catch {
        Modal.error({
          content: "An error occured while loading the scene.",
        });
      } finally {
        setIsLoadingSceneTemplate(false);
      }
    };

    getScene();
  }, [
    sceneTemplatesClient,
    setSceneTemplate,
    props.sceneTemplateId,
    setValues,
  ]);

  if (!sceneTemplate || isLoadingSceneTemplate || templateTagsLoading)
    return <Spin />;

  return (
    <Modal
      okText="Update"
      open={props.visible}
      okButtonProps={{
        loading: isLoadingSceneTemplate,
      }}
      onOk={submitForm}
      onCancel={props.hide}
      cancelButtonProps={{
        loading: isLoadingSceneTemplate,
      }}
      style={{ width: "100%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <label style={{ marginRight: 10 }}>Name</label>
        <Input
          value={values.name}
          onChange={(e) =>
            setValues((values) => ({ ...values, name: e.currentTarget.value }))
          }
        />

        <label style={{ marginRight: 10 }}>Ratio</label>
        <Select
          onSelect={(key) =>
            setValues((values) => ({ ...values, preferredRatio: key }))
          }
          value={values.preferredRatio}
        >
          {ratios.map((ratio) => (
            <Select.Option key={ratio.value} value={ratio.value}>
              {ratio.name}
            </Select.Option>
          ))}
        </Select>

        <label style={{ marginRight: 10 }}>Visibility</label>
        <Select
          onSelect={(key) =>
            setValues((values) => ({ ...values, visibility: key }))
          }
          value={values.visibility}
        >
          {["hidden", "public", "private"].map((visibility) => (
            <Select.Option key={visibility} value={visibility}>
              {visibility}
            </Select.Option>
          ))}
        </Select>

        {values.visibility === "private" ? (
          <OrganizationSearch
            value={values.organizationId ? [values.organizationId] : undefined}
            showId
            placeholder="Select organization"
            onSelect={(id) =>
              setValues((values) => ({ ...values, organizationId: id }))
            }
            onDeselect={() =>
              setValues((values) => ({ ...values, organizationId: undefined }))
            }
            onClear={() =>
              setValues((values) => ({ ...values, organizationId: undefined }))
            }
          />
        ) : null}
        <label style={{ marginRight: 10 }}>Tags</label>
        <Select
          onSelect={(key) =>
            setValues((values) => ({
              ...values,
              tags: [
                ...values.tags,
                templateTags.find((tag) => tag.name === key)!,
              ],
            }))
          }
          onDeselect={(key) =>
            setValues((values) => ({
              ...values,
              tags: values.tags.filter((tag) => tag.name !== key),
            }))
          }
          mode="multiple"
          value={values.tags?.map((map) => map.name)}
        >
          {templateTags.map((tag) => (
            <Select.Option key={tag.name} value={tag.name}>
              {tag.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};
