import { useEffect, useState } from "react";

import { Modal } from "antd";

import type { SceneTemplateTag } from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";

export const useListTemplateTags = () => {
  const [templateTags, setTemplateTags] = useState<SceneTemplateTag[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const client = useClientsStore((state) => state.sceneTemplateTagsClient);

  useEffect(() => {
    const listSceneTemplateTagsv2 = async () => {
      try {
        setIsLoading(true);

        const data = (
          await client.listSceneTemplateTagsV2({
            pageSize: 1000,
            returnRelatedNameTranslation: true,
          })
        ).data;

        setTemplateTags(data.data);
      } catch {
        Modal.error({
          content: "An error occured while listing the template tags.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    listSceneTemplateTagsv2();
  }, [client, setTemplateTags]);

  return {
    templateTags,
    setTemplateTags,
    isLoading,
  };
};
